import React from "react";
import { Icon as Iconfiy } from "@iconify/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Autoplay,
  Mousewheel,
  Keyboard,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const SwiperComponent = (props) => {
  const { title, subtitle, classTitle, breakpoints, style } = props;
  const swiperRef = React.useRef(null);

  const handleNext = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      swiper.slideNext();
    }
  };
  const handlePrev = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      swiper.slidePrev();
    }
  };
  return (
    <div className={classTitle}>
      <h2 className="mainSectionsTitle">{title}</h2>
      {subtitle && <span className="mainSectionsSubTitle">{subtitle}</span>}
      {/* <span className="iconContainerLeft">
        <Iconfiy
          width="32"
          height="32"
          color="#36b54a"
          icon="mingcute:arrow-left-fill"
          onClick={handlePrev}
        />
      </span>
      <span className="iconContainerRight">
        <Iconfiy
          width="32"
          height="32"
          color="#36b54a"
          icon="mingcute:arrow-right-fill"
          onClick={handleNext}
        />
      </span> */}
      <Swiper
        ref={swiperRef}
        modules={[
          Navigation,
          Pagination,
          A11y,
          EffectFade,
          Autoplay,
          Scrollbar,
          Mousewheel,
        ]}
        breakpoints={breakpoints}
        scrollbar={{draggable:true}}
        mousewheel
        className="swiperMain"
        style={{paddingBottom:"20px"}}
      >
        {props.children}
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
