import React, { useState, useRef, useEffect, useCallback } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import {
//   Navigation,
//   Pagination,
//   Scrollbar,
//   A11y,
//   EffectFade,
//   Autoplay,
//   Mousewheel,
//   Keyboard,
// } from "swiper";
// import Typography from "@mui/material/Typography";
import { Outlet, Link, useNavigate } from "react-router-dom";
// import { BiCurrentLocation } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";

import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
// import Card from "@mui/material/Card";
// import Drawer from "@mui/material/Drawer";
// import Button from "@mui/material/Button";
// import CardContent from "@mui/material/CardContent";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// import Menu from "@mui/material/Icon";
// import Icon from "@mui/material/Icon";
import MenuIcon from "@mui/icons-material/Menu";
// import MyLocationIcon from "@mui/icons-material/MyLocation";
// import slider1 from "../assets/n1.jpeg";
// import slider2 from "../assets/n2.jpeg";
// import slider3 from "../assets/n3.jpeg";
// import slider4 from "../assets/n4.jpeg";
// import { HomPageSlider } from "./HomPageSlider";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import logo from "../assets/logo.png";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import LandscapeIcon from "@mui/icons-material/Landscape";
import DownloadIcon from "@mui/icons-material/Download";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CallIcon from "@mui/icons-material/Call";
import PersonIcon from "@mui/icons-material/Person";
import { useSelector } from "react-redux";

// import InstagramIcon from "@mui/icons-material/Instagram";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Icon as Iconfiy } from "@iconify/react";

// import CallIcon from '@mui/icons-material/Call';
// import Radium, { StyleRoot } from "radium";

import "./layout.css";
// import { BackendEvents } from "./BackendEvents";
import { Footer } from "./Footer";
import dataJson from "./cities.json";
import { getAllCities } from "../api";
import { useDispatch } from "react-redux";
import {
  updateUserInformation,
  // updateUserInformationTotalFees,
} from "../redux/actions";
import SideBar from "./SideBar";
let Anchor = "top" | "left" | "bottom" | '"right"';

export const LayoutNew = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInformation);

  const [loading, setLoading] = useState(false);
  const [citiesData, setMainData] = useState([]);
  const [error, setError] = useState("");

  const [selectCityModal, setSelectCityModal] = useState(false);
  const [selectedCity, setSelectedcity] = useState("Pune");
  const [cities, setCities] = useState([]);
  const headerRef = useRef(null);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const apiData = await getAllCities();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getAllCities", apiData);
        setMainData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
     if (err.response) {
       setError(err.response.data.message);
       setErrorToast(err.response.data.message);
       navigate(`/errorPage/${err.response.data.message}`);
     } else if (err.name === "AxiosError") {
       setError(err.message);
       setErrorToast(err.message);
       navigate(`/errorPage/${err.message}`);
     } else {
       setError("something went wrong");
       setErrorToast("something went wrong");
     }
    }
    setLoading(false);
  }, []);

  const setErrorToast = (errorFromBackend) => {
    toast.error(
      <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
        Error: {error.length > 0 ? error : errorFromBackend}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
        theme: "colored",
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  useEffect(() => {
    setCities(dataJson.cities);
    // console.log("cites2", cities);
    // return () => {
    //   second
    // }
  }, [cities]);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const LogoutFunction = () => {
    // console.log("LogoutFunction called");
    const user_type = localStorage.getItem("user_type");
    localStorage.clear();
    navigate("/");
  };
  const navbarList = [
    {
      name: "Home",
      icon: <HomeIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "Events",
      icon: <LandscapeIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "Download",
      icon: <DownloadIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "About",
      icon: <BusinessIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "Team",
      icon: <PeopleAltIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "Contact",
      icon: <CallIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "Login",
      icon: <PersonIcon style={{ color: "#36b54a" }} />,
    },
  ];
  const SearchPageFunction = () => {
    navigate("/SearchTrips");
  };

  const checkOnClick = (name) => {
    // console.log("checkOnClickcalled",name)
    if(name === "Logout")
    {
      LogoutFunction();
    }
    else if(name === "Logout")
    {
      LogoutFunction();
    }
    else if(name === "Events")
    {
      SearchPageFunction();
    }
    else if(name === "Login")
    {
      navigate("/CustomerLogin");
    }
    else if(name === "About")
    {
      navigate("/About");
    }
    else if(name === "Team")
    {
      navigate("/Teams");
    }
    else if(name === "Contact")
    {
      navigate("/Contact");
    }

  }
  const list = (anchor) => (
    <Box
      sx={{
        width: 300,
        height: "100%",
        zIndex: 3000000,
        fontFamily: "Montserrat",
        position: "relative",
        maxHeight: "900px",
        overflow: "auto",
        // textAlign: "left",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem key={"close"} sx={{ marginTop: 2, marginLeft: "20%" }}>
          <ListItemButton></ListItemButton>
          <ListItemText
            onClick={toggleDrawer("right", false)}
            primary={<CloseIcon fontSize="1rem" />}
          />
        </ListItem>
        {navbarList &&
          navbarList.map((data, i) => {
            return (
              <ListItem
                onClick={() => {
                  checkOnClick(data.name);
                }}
                key={data.name}
                sx={{ marginTop: "-10px" }}
              >
                <ListItemButton>
                  <ListItemIcon>{data.icon}</ListItemIcon>
                  <ListItemText
                    onClick={data.name === "login" ? LogoutFunction() : null}
                    sx={{
                      marginLeft: "-12px",
                      color: "#1c1c1c",
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                    }}
                    primary={data.name}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>

      <div className="bottomLeftCanvas" style={{}}>
        <Divider />
        <div style={{ padding: "10px" }}>
          <ListItemButton>
            <ListItemText
              sx={{ color: "#36b54a", fontWeight: 600 }}
              primary="Contact"
            />
          </ListItemButton>
          <ul style={{ marginLeft: "20px" }}>
            <li>
              <Iconfiy icon="material-symbols:call" />{" "}
              {/* <span style={{ marginLeft: "5px" }}>+91 9284544815</span> */}
            </li>
            <li>
              <Iconfiy icon="material-symbols:call" />{" "}
              <span style={{ marginLeft: "5px" }}>+91 9284544815</span>
            </li>
            <li>
              <Iconfiy icon="material-symbols:alternate-email" />{" "}
              <span style={{ marginLeft: "5px" }}>trekpaanda@gmail.com</span>
            </li>
          </ul>
          <div className="scoialDIv">
            <a href="http://instagram.com/trek_panda" className="socialLogo">
              <Iconfiy icon="mdi:instagram" width="24" height="24" />{" "}
            </a>
            <a href="http://facebook.com/trekpanda/" className="socialLogo">
              <Iconfiy icon="mdi:facebook" width="24" height="24" />{" "}
            </a>
            <a href="http://youtube.com/channel/UCswW5joA4qDfgBv3lOgCTYA" className="socialLogo">
              <Iconfiy icon="mdi:youtube" width="24" height="24" />{" "}
            </a>
            <a href="https://wa.me/918447937907" className="socialLogo">
              <Iconfiy icon="mdi:whatsapp" width="24" height="24" />{" "}
            </a>
            <a href="https://maps.app.goo.gl/k4HveeQnf7CyBAAa6" className="socialLogo">
              <Iconfiy icon="mdi:map-marker" width="24" height="24" />{" "}
            </a>
          </div>
        </div>
      </div>
    </Box>
  );
  const updateCity = (city) => {
    setSelectedcity(city);
    const updatedInfo = {
      selectedCity: city,
    };

    dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
      .then(() => {
        // const customer_id = localStorage.getItem("customer_id");
        // if (customer_id) {
        //   navigate("/customerPayNowScreen");
        // } else {
        //   navigate("/CustomerLogin");
        // }
      })
      .catch((error) => {
        alert("Failed to update user information.");
        // Handle error scenarios if needed
      });
    setTimeout(() => {
      setSelectCityModal(false);
    }, 1000);
  };
  const toggleModal = () => {
    setSelectCityModal(!selectCityModal);
    headerRef.current.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  return (
    <div className="">
      <ToastContainer />

      {selectCityModal === true ? (
        <section
          style={{ opacity: 1, zIndex: 100000000, backgroundColor: "#ffffff" }}
        >
          <div id="location-popup">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-11 col-md-11 col-sm-11"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div id="location-popup-heading">
                    Select Your Journey Location! {userInfo.selectedCity}
                  </div>
                  {/* <CloseIcon style={{marginTop:15}} fontSize="25px" /> */}
                </div>

                <div
                  className="col-lg-1 col-md-1 col-sm-11 mt-4 text-right"
                  onClick={toggleModal}
                >
                  <span className="float-right">
                    <i className="fal fa-times" />
                  </span>
                </div>
              </div>
            </div>

            <div id="location-popup-city">
              <div id="location-popup-city-header">POPULAR CITY</div>
              <div id="top-city-locations">
                {citiesData.length > 0 &&
                  citiesData?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          updateCity(item.name);
                        }}
                        id={i}
                      >
                        <img
                          alt="testing"
                          src={item.icon}
                          width="60px"
                          loading="lazy"
                          // style={{
                          //   border: "2px solid",
                          //   borderRadius: "30px",
                          // }}
                        />
                        <div
                          className="text-center"
                          style={{
                            color: selectedCity === item.name ? "#36b54a" : "",
                          }}
                        >
                          {item.name}
                        </div>
                      </div>
                    );
                  })}

                {/* <div onclick="locationUpdate(id)" id="Bangalore">
                  <img
                    src="https://cdn-images.cure.fit/www-curefit-com/image/upload/fl_progressive,f_auto,q_auto:eco,w_50,h_50,ar_1/dpr_2/image/cities/blr_new.png"
                    width="60px"
                    alt
                  />
                  <div>Bangalore</div>
                </div>
                <div onclick="locationUpdate(id)" id="Delhi NCR">
                  <img
                    src="https://cdn-images.cure.fit/www-curefit-com/image/upload/fl_progressive,f_auto,q_auto:eco,w_50,h_50,ar_1/dpr_2/image/cities/ncr_new.png"
                    width="60px"
                    alt
                  />
                  <div>Delhi NCR</div>
                </div>
                <div onclick="locationUpdate(id)" id="Hyderabad">
                  <img
                    src="https://cdn-images.cure.fit/www-curefit-com/image/upload/fl_progressive,f_auto,q_auto:eco,w_50,h_50,ar_1/dpr_2/image/cities/hyd_new.png"
                    width="60px"
                    alt
                  />
                  <div>Hyderabad</div>
                </div>
                <div onclick="locationUpdate(id)" id="Mumbai">
                  <img
                    src="https://cdn-images.cure.fit/www-curefit-com/image/upload/fl_progressive,f_auto,q_auto:eco,w_50,h_50,ar_1/dpr_2/image/cities/mumbai_selected.png"
                    width="60px"
                    alt
                  />
                  <div>Mumbai</div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      ) : null}
      <div className="mainLogo" onClick={() => {}} ref={headerRef}>
        <img
          onClick={() => {
            navigate("/");
          }}
          src={logo}
          style={{ height: "32px", width: "132px", marginTop: "10px" }}
          alt="logo"
        />
      </div>
      <React.Fragment key={Anchor}>
        <Link to="/searchAllTrips">
          <SearchIcon className="search_icon_style" />
        </Link>
        <MenuIcon
          onClick={toggleDrawer("right", true)}
          className="menu_icon_style"
          color="white"
        />

        {/* <MyLocationIcon
          onClick={toggleModal}
          className="myLocation"
          color="white"
        /> */}
        <SideBar toggleDrawer={toggleDrawer} state={state} />
      </React.Fragment>

      <Outlet />

      <Footer />
    </div>
  );
};
