import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";
export const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: smooth scrolling animation
    });
  };
  
  return (
    <>
      <section className="footer-area section-bg padding-top-100px padding-top-sm-35px padding-bottom-30px">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 responsive-column">
              <div className="footer-item text-left">
                <img src={logo} alt="logo" width={"100px"} height="100px" />
                {/* end logo */}
                <p className="footer__desc">
                  At TrekPanda, we strongly believe in protecting consumer
                  privacy. That's why we only ask you for information related to
                  the services we provide.{" "}
                </p>
              </div>
              {/* end footer-item */}
            </div>
            {/* end col-lg-3 */}
            <div className="col-lg-3 col-sm-6 col-xs-6">
              <div className="footer-item">
                <h4
                  className="title curve-shape pb-3 margin-bottom-20px"
                  data-text="curvs"
                >
                  Company
                </h4>
                <ul className="list-items list--items" >
                  <li style={{paddingLeft:"0px"}}>
                    <Link to="/SearchAllTrips" >Events</Link>
                  </li>
                  <li style={{paddingLeft:"0px"}}>
                    <Link to="/Contact" onClick={scrollToTop}>Contact Us</Link>
                    {/* <a href="services.html">Contact Us</a> */}
                  </li>
                  <li style={{paddingLeft:"0px"}}>
                    <Link to="/About" onClick={scrollToTop}>About Us</Link>
                    {/* <a href="#">About Us</a> */}
                  </li>
                  <li style={{paddingLeft:"0px"}}>
                    <Link to="/Careers" onClick={scrollToTop}>Jobs</Link>
                  </li>
                  <li style={{paddingLeft:"0px"}}>
                    <Link to="/Blogs" onClick={scrollToTop}>Blogs</Link>
                  </li>
                  {/* <li>
                      <a href="contact.html">Support</a>
                    </li>
                    <li>
                      <a href="#">Advertising</a>
                    </li> */}
                </ul>
              </div>
              {/* end footer-item */}
            </div>
            {/* end col-lg-3 */}
            <div className="col-lg-3 col-sm-6 col-xs-6">
              <div className="footer-item">
                <h4
                  className="title curve-shape pb-3 margin-bottom-20px"
                  data-text="curvs"
                >
                  Quick Links
                </h4>
                <ul className="list-items list--items">
                  <li style={{paddingLeft:"0px"}}>
                    <Link to="/PrivacyPolicy" onClick={scrollToTop}>Privacy Policy</Link>
                  </li>
                  <li style={{paddingLeft:"0px"}}>
                    <Link to="/CancellationPolicy" onClick={scrollToTop}>Cancellation Policy</Link>
                    {/* <a href="#">Cancellation Policy</a> */}
                  </li>
                  {/* <li style={{paddingLeft:"0px"}}>
                      <a href="#">Terms & Conditions</a>
                    </li> */}
                  {/* <li>
                      <a href="#">Disclaimer</a>
                    </li> */}
                  {/* <li>
                      <a href="#">About Us</a>
                    </li> */}
                  {/* <li>
                      <a href="#">Trizen Reviews</a>
                    </li> */}
                </ul>
              </div>
              {/* end footer-item */}
            </div>
            {/* end col-lg-3 */}
          
            <div className="col-lg-3 col-sm-6">
              <div className="footer-item">
                <h4
                  className="title curve-shape pb-3 margin-bottom-20px"
                  data-text="curvs"
                >
                  Our Office 
                </h4>
                <p className="footer__desc pb-3">
                Pratibha apt, 2nd floor Senapati Bapat Rd, near Ratna Hospital, Shivaji Co operative Housing Society, Ramoshivadi, Wadarvadi, Pune, Maharashtra 411016
                </p>
               
              </div>
              {/* end footer-item */}
            </div>
            {/* end col-lg-3 */}
          </div>
          {/* end row */}
          <div className="row align-items-center">
            {/* <div className="col-lg-8">
                <div className="term-box footer-item">
                  <ul className="list-items list--items d-flex align-items-center">
                    <li>
                      <a href="#">Terms &amp; Conditions</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Help Center</a>
                    </li>
                  </ul>
                </div>
              </div> */}
            {/* end col-lg-8 */}
            <div className="col-lg-12">
              <div className="footer-social-box text-right">
                <ul className="social-profile">
                <li>
                    <a href="http://instagram.com/trek_panda">
                      <i className="lab la-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="http://facebook.com/trekpanda/">
                      <i className="lab la-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="http://youtube.com/channel/UCswW5joA4qDfgBv3lOgCTYA">
                      <i className="lab la-youtube" />
                    </a>
                  </li>
                 
                  <li>
                    <a href="https://wa.me/918447937907">
                      <i className="lab la-whatsapp" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* end col-lg-4 */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
        <div className="section-block mt-4" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="copy-right padding-top-30px">
                <p className="copy__desc">
                  © Copyright TrekPanda 2023. Made with
                  <span className="la la-heart" /> by
                  <Link to="/">
                    TrekPanda
                  </Link>
                </p>
              </div>
              {/* end copy-right */}
            </div>
            {/* end col-lg-7 */}
            <div className="col-lg-5">
              <div className="copy-right-content d-flex align-items-center justify-content-end padding-top-30px">
                <h3 className="title font-size-15 pr-2">We Accept</h3>
                <img src="images/payment-img.png" alt="payment_img" />
              </div>
              {/* end copy-right-content */}
            </div>
            {/* end col-lg-5 */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      <div id="back-to-top">
        <i className="la la-angle-up" title="Go top" />
      </div>
    </>
  );
};
