import React, { useCallback, useEffect, useState } from "react";
import { getTeam } from "../api";
export const Teams = () => {
  const [error, setError] = useState("");
  const [mainData, setMainData] = useState([]);

  const getData = useCallback(async () => {
    try {
      const apiData = await getTeam();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        console.log("getTeam", apiData.data);
        setMainData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
    }
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <div style={{ position: "relative", marginBottom: "50px" }}>
      <div
        style={{
          width: "100%",
          height: "100.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        
      </div>
      <div className="p-4">
        <div className="container" style={{ width: "100%" }}>
          <div className="row" style={{ width: "100%" }}>
            {mainData &&
              mainData.map((data, i) => {
                return (
                  <div
                    className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3"
                    key={i}
                  >
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <img src={data.icon} className="teamImage" alt="" />
                      <span className="teamsDetailsName">{data.name}</span>
                      <span className="teamsDetailsNameDesignation">
                        {data.designation}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
