import React from 'react'
import { Navigate } from 'react-router-dom';
import logo from "../logo.png";

const NoInternet = () => {
  return (
    <div className="text-center">
      <img
        onClick={() => {
          Navigate("/");
        }}
        src={logo}
        style={{ height: "125px", margin:"3rem" }}
        alt="logo"
      />
      <h1 className=" m-5">No Internet Connection</h1>
      <button
        className="btn btn-success"
        onClick={() => window.location.reload()}
      >
        Try Again
      </button>
    </div>
  );
}

export default NoInternet