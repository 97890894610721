import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import logo from "../logo.png";

const ErrorPage = () => {
    const {error} = useParams();
    const navigate = useNavigate();
  return (
    <div className="text-center">
      <img
        onClick={() => {
          navigate("/");
        }}
        src={logo}
        style={{ height: "125px", margin: "3rem" }}
        alt="logo"
      />
      <h1 className="m-5">Unexpected Error</h1>
      <p className="m-5">{error}</p>
      <button className="btn btn-success" onClick={() => navigate("/")}>
        Try Again
      </button>
    </div>
  );
}

export default ErrorPage