import React, { useCallback, useState, useEffect } from "react";
// import Button from "@mui/material/Button";
// import { Icon as Iconfiy } from "@iconify/react";
// import { useNavigate } from "react-router-dom";
// import Card from "@mui/material/Card";
// import Drawer from "@mui/material/Drawer";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import TestimonialCard from "material-testimonial-card";
import { getAllCities, tripByCityName } from "../api";
import { ToastContainer} from "react-toastify";
// import FullCalendar from "@fullcalendar/react"; // must go before plugins
// import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import RevoCalendar from "revo-calendar";
import TripCardView from "../component/TripCardView";
// import section2 from "../assets/section2.jpeg";
import SmallTripView from "../component/SmallTripView";
import moment from 'moment';
import { useDispatch } from "react-redux";

import {
  updateUserInformation,
} from "../redux/actions";
import { useSelector } from "react-redux";

export const SearchTrips = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(Date.now());

  const userInfo = useSelector((state) => state.userInformation);

  const [eventsData, setEventsData] = useState([]);
  const [citiesData, setMainData] = useState([]);
  const [tripsData, setTripsData] = useState([]);
  const getData = useCallback(async () => {
    setTripsData();
    setEventsData();
    try {

      const apiData = await getAllCities();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getAllCities", apiData);
        setMainData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
      // if (err.response) {
      //   setError(err.response.data.message);
      //   setErrorToast(err.response.data.message);
      // } else if (err.name === "AxiosError") {
      //   setError(err.message);
      //   setErrorToast(err.message);
      // } else {
      //   setError("something went wrong");
      //   setErrorToast("something went wrong");
      // }
    }
    // setLoading(false);
  }, []);
  const getDataByDatesOfMonthNew = useCallback((dataArray, milliseconds) => {
    // console.log("getDataByDatesOfMonthNew",dataArray);
    const filteredData = dataArray.filter((item) => {
      return item.cities.some((city) => {
        return city.testName2 === userInfo.selectedCity && city.monthData.some((month) => {
          // console.log("Month123123",month);
          return month.dates.includes(milliseconds);
        });
      });
    });
    return filteredData;
  },[userInfo.selectedCity]);
  const getTripsData = useCallback(async () => {
    // setEventsData();
    try {
      let body = {
        cityName:userInfo.selectedCity
      }
      const apiData = await tripByCityName(body);
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getTripsData", apiData);
        const filteredData = getDataByDatesOfMonthNew(apiData.data, selectedDate);
        // console.log("filteredData", filteredData);

        setTripsData(filteredData);
        const getUniqueValues = (array) => {
          const uniqueValues = [...new Set(array)];
          return uniqueValues.map((value) => ({
            date: value,
            allDay: true,
          }));
        };
      
      
        let trips = apiData.data;
        // Extract unique datesOfMonthNew values
        const uniqueDatesOfMonthNew = getUniqueValues(
          trips.flatMap((trip) =>
            trip.cities.flatMap((city) =>
              city.monthData.flatMap((month) => month.datesOfMonthNew)
            )
          )
        );
        // setEventsData()
        // console.log("uniqueDatesOfMonthNew",uniqueDatesOfMonthNew);
        setEventsData(uniqueDatesOfMonthNew);
      }
    } catch (err) {
      console.log("err123", err);
      // if (err.response) {
      //   setError(err.response.data.message);
      //   setErrorToast(err.response.data.message);
      // } else if (err.name === "AxiosError") {
      //   setError(err.message);
      //   setErrorToast(err.message);
      // } else {
      //   setError("something went wrong");
      //   setErrorToast("something went wrong");
      // }
    }
    // setLoading(false);
  }, [userInfo,selectedDate, getDataByDatesOfMonthNew]);

  useEffect(() => {
    getData();
    getTripsData();
  }, [userInfo,selectedDate, getData, getTripsData]);

  // let id = "123";
  // const [mainData, setMainData] = useState([]);
  const [error, setError] = useState("");

  const convertDateToMilliseconds = (dateObject) => {
    const { day, month, year } = dateObject;
    const date = new Date(year, (month+1) - 1, day);
    return date.getTime();
  };
  const dateSelectedEvent = (title) => {
    // console.log("title", title);
    let dateNew = convertDateToMilliseconds(title);
    setSelectedDate(dateNew);
  };
  // var events = [
  //   // {
  //   //   name: "Buyout",
  //   //   date: Date.now(),
  //   //   allDay: true,
  //   // },
  //   {
  //     name: "Reservation",
  //     date: Date.now(),
  //     extra: {
  //       icon: "M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09           4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z",
  //       text: "7 People",
  //     },
  //   },
  // ];
  useEffect(() => {
    //     console.log("useEffect called");
    //     getData();
  }, []);
  // const navigate = useNavigate();

  // const setErrorToast = (errorFromBackend) => {
  //   toast.error(
  //     <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
  //       Error: {error.length > 0 ? error : errorFromBackend}
  //     </p>,
  //     {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //       autoClose: 2000,
  //       theme: "colored",
  //     }
  //   );
  // };
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "168.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        <h2 className="loginScreenText">Search Events</h2>
        <ToastContainer />
      </div>

      <div className="phoneNumberDivLogin">
        <div className="row">
          <div className="col-lg-8">
            <RevoCalendar
              events={eventsData}
              style={{
                borderRadius: "5px",
                border: "5px solid #36b54a",
                height: "100%",
                width: "100%",
              }}
              highlightToday={true}
              lang="en"
              primaryColor="#36b54a"
              secondaryColor="#ffffff"
              todayColor="#ffffff"
              textColor="black"
              indicatorColor="orange"
              animationSpeed={300}
              sidebarWidth={180}
              detailWidth={280}
              detailDefault={false}
              showDetailToggler={false}
              showSidebarToggler={true}
              sidebarDefault={true}
              onePanelAtATime={false}
              // allowDeleteEvent={true}
              // allowAddEvent={true}
              openDetailsOnDateSelection={false}
              // timeFormat24={true}
              showAllDayLabel={true}
              detailDateFormat="DD/MM/YYYY"
              // deleteEvent={deleteEvent}
              // addEvent={addEvent}
              dateSelected={dateSelectedEvent}
            />
          </div>
          <div
            className="col-lg-4"
            style={{
              backgroundColor: "#fbfbfb",
              borderRadius: "10px",
            }}
          >
            <div className="row">
              <div className="col-lg-12">
                <h3 style={{ marginTop: 10, marginBottom: 10 }}>
                  {" "}
                  {moment(selectedDate).format("MMM Do YY")}
                  {/* {moment(moment(selectedDate,'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss')).valueOf()} */}
                  {/* {moment(selectedDate,'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss')} */}
                  {/* {selectedDate} */}
                </h3>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label htmlFor="" className="basicPartiCipant">
                    Select City 
                  </label>
                  <select
                    placeholder="Enter Mobile Number"
                    className="form-control inputTypeParticipant"
                    onChange={(e) => {
                      // console.log("eeee",e.target.value);
                      setEventsData();

                      const updatedInfo = {
                        selectedCity: e.target.value,
                      };
                  
                      dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
                        .then(() => {
                        })
                        .catch((error) => {
                          alert("Failed to update user information.");
                          // Handle error scenarios if needed
                        });
                    }}
                  >
                      {citiesData.length > 0 &&
                  citiesData?.map((item, i) => {
                    return (<option selected={userInfo.selectedCity === item.name} value={item.name}>{item.name}</option>)})}
                   
                    {/* <option value="Male">Male</option>
                    <option value="Female">Female</option> */}
                  </select>
                </div>
              </div>
            </div>

            <div style={{ overflowY: "auto", maxHeight: "600px" }}>
              {tripsData && tripsData.map((data,i)=>{
                return(<TripCardView tripDetails={data} />)
              })}
              {tripsData && tripsData.length === 0 && (<div className="alert alert-info">No Data Found</div>)
              }
              
            
            </div>
          </div>
          <div className="col-lg-12 mt-5 mb-5">
            <span className="mainSectionsTitle">Trips</span>

            <div className="row mt-3 text-center">
              {tripsData && tripsData.map((data,i)=>{
                return(<div className="col-lg-3 text-center"><SmallTripView tripDetails={data} /></div>)
              })}
              {tripsData && tripsData.length === 0 && (<div className="alert alert-info">No Data Found</div>)
              }
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
