// actions.js

// Action types
export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';
export const UPDATE_USER_INFORMATION_FEES = 'UPDATE_USER_INFORMATION_FEES';

// Action creators
export const updateUserInformation = (userInfo) => {
      return async (dispatch) => {
        try {
          // Simulate an asynchronous API call to update user information
      //     await someAsyncUpdateUserInformationFunction(userInfo);
          
          dispatch({
            type: UPDATE_USER_INFORMATION,
            payload: userInfo,
          });
          
          return Promise.resolve(); // Resolve the promise when the update is successful
        } catch (error) {
          // Handle any error scenarios
          return Promise.reject(error); // Reject the promise if there's an error
        }
      };
    };

    export const updateUserInformationTotalFees = (totalFees) => {
      return async (dispatch) => {
        try {
          // Simulate an asynchronous API call to update user information
          // await someAsyncUpdateUserInformationFunction(totalFees);
          
          dispatch({
            type: UPDATE_USER_INFORMATION_FEES,
            payload: { total_fees: totalFees },
          });
          
          return Promise.resolve(); // Resolve the promise when the update is successful
        } catch (error) {
          // Handle any error scenarios
          return Promise.reject(error); // Reject the promise if there's an error
        }
      };
    };
    