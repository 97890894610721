import React from "react";
import { Modal, Button } from "react-bootstrap";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ImageModal.css";
SwiperCore.use([Navigation]);

const ImageModal = ({
  typeOfModal,
  show,
  onHide,
  images,
  initialSlide,
  attractions,
}) => {
  const setImages = typeOfModal === "images" ? images : attractions;

  return (
    <Modal
      show={show}
      onHide={onHide}
      //   dialogClassName="custom-modal"
      //   bsClass="my-modal-dialog"
      //  fullscreen="xxl"
      //   centered
      //   dialogClassName="fullscreen-modal"
      size="xl"
    >
      <Modal.Body>
        <Swiper
          initialSlide={initialSlide}
          navigation
          spaceBetween={10}
          slidesPerView={1}
        >
          {setImages?.map((image, index) => (
            <SwiperSlide key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "75vh",
                  overflow: "hidden",
                }}
              >
                <img
                  src={image.link}
                  alt={`Slide ${index}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
