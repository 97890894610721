import React from "react";
import { useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import section2 from "../assets/section2.jpeg";

const SmallTripView = ({ tripDetails }) => {
  const navigate = useNavigate();

  return (
    <div
      href="#"
      style={{ marginTop: "20px" }}
      onClick={() => {
        navigate(`/tours/${tripDetails?.slug}`);
      }}
    >
      <CardMedia
        sx={{
          height: "200px",
          width: "200px",
          borderRadius: "10px",
        }}
        image={tripDetails?.images[0]?.link}
        title={tripDetails?.title}
      />
      <span className="subSectionTitle">{tripDetails?.title}</span>
    </div>
  );
};

export default SmallTripView;
