import React, { useCallback, useEffect, useState } from "react";
// import { Testimonial } from "../component/Testimonial";
// import image from "../assets/fivehundred.png";
import { Icon as Iconfiy } from "@iconify/react";
import { getContacts } from "../api";
import { toast } from "react-toastify";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Contact = () => {
  const [error, setError] = useState("");
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);

    const setErrorToast = useCallback((errorFromBackend) => {
      toast.error(
        <p className="text-white tx-16 mb-0">
          Error: {error.length > 0 ? error : errorFromBackend}
        </p>,
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 2000,
          theme: "colored",
        }
      );
    },[error]);

  const getData = useCallback(async () => {
    try {
      const apiData = await getContacts();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getAllCities", apiData);
        setMainData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        setErrorToast(err.response.data.message);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        setErrorToast(err.message);
      } else {
        setError("something went wrong");
        setErrorToast("something went wrong");
      }
    }
    setLoading(false);
  }, [setErrorToast]);

    useEffect(() => {
      getData();
    }, [getData]);

  return (
    <div style={{ position: "relative", marginBottom: "50px" }}>
      <div
        style={{
          width: "100%",
          height: "200.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
          // paddingLeft:"12%",
          // paddingRight:"12%",
        }}
      >
        <h2 className="loginScreenText">Contact</h2>
        <h5 className="loginScreenTextBelow">
          Life is either a daring adventure or nothing.
        </h5>
      </div>
     
      <div className="phoneNumberDivLogin">
        <div className="row">
          {mainData &&
            mainData.map((data) => (
              <Col lg={4}>
                <div className="contactUsDiv">
                  <span className="contactUsHeading">{data?.title}</span>
                  <br />
                  <br />
                  <span className="contactUsDetailAddress">
                    {data?.address}
                  </span>
                  <br />
                  <span className="contactUsDetailAddress">
                    Office Timings: {data?.officeTiming}
                  </span>
                  <br />
                  <br />
                  <Iconfiy icon="material-symbols:call" />
                  <span style={{ marginLeft: "5px" }}>{data?.contactNum1}</span>
                  <br />
                  <Iconfiy icon="material-symbols:call" />
                  <span style={{ marginLeft: "5px" }}>{data?.contactNum2}</span>
                  <div className="text-right">
                    <Link target="_blank" to={data?.mapUrl}>
                      <span className="contactUsViewMap">View On Map</span>
                      <Iconfiy color="#36b54a" icon="iconoir:arrow-tr" />
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
        </div>
      </div>
    </div>
  );
};
