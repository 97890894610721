import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk'; // Import redux-thunk

// Import your reducer
import rootReducer from './reducer'; // Import your actual reducer

// Configure persist options
const persistConfig = {
  key: 'root', // this key is used to store data in local storage, you can change it if needed
  storage, // specify storage to use (localStorage by default)
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with middleware including thunk
const store = createStore(
  persistedReducer,
  applyMiddleware(thunk) // Apply redux-thunk middleware
);

// Create a persisted store
const persistor = persistStore(store);

export { store, persistor };
