import ReactDOM from "react-dom/client";

import "./index.css";
import "./quill.css";

import reportWebVitals from "./reportWebVitals";

import App from "./App";

// Create the Redux store
// const store = createStore(reducer);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals();
