import React, { useState, useEffect } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CardMedia from "@mui/material/CardMedia";
import {
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import LazyLoad from "react-lazyload";
import moment from "moment";
export const TimelineComponent = (props) => {
  const selectedDateFullFormat = props?.selectedDateFullFormat;
  const inputDate = moment(selectedDateFullFormat, "DD-MM-YYYY");
  const [largeShow, setlargeShow] = useState(false);
  const largemodalClose = () => setlargeShow(false);
  const largemodalShow = () => setlargeShow(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [modalData, setModalData] = useState([]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const closeModalFunction = () => {
    largemodalClose();
    setModalData([]);
    // setModalTitle();
  };
  const isMobile = width <= 768;
  const iternityDetails = props.iternityDetails;
  // console.log("iternityDetails", iternityDetails);
  if (props.iternityDetails.length === 0) {
    return null;
  }
  return (
    <div className="timelineContent">
      <span className="mainSectionsTitle">{"Schedule"}</span>
      <Modal size="lg" show={largeShow} onHide={closeModalFunction}>
        <ModalHeader>
          <ModalTitle>Schedule Details</ModalTitle>
          <span className="d-flex ms-auto" onClick={closeModalFunction}>
            <i className="fe fe-x ms-auto"></i>
          </span>
        </ModalHeader>
        <ModalBody>
          <div
            className="container"
            dangerouslySetInnerHTML={{ __html: modalData }}
          ></div>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={closeModalFunction}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
        className="timelineSchedule"
      >
        {iternityDetails &&
          iternityDetails.map((data, i) => {
            const newDate = moment(inputDate).add(i, "day");
            const formattedDate = newDate.format("Do MMM YYYY");

            return (
              <TimelineItem key={i}>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      backgroundColor: "#36b54a",
                      marginBottom: "-0.5px",
                      marginTop: "-0.5px",
                    }}
                  />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div className="timelineULOL">
                    <span className="sheduleDayTitle">Day {i + 1}</span>
                    {selectedDateFullFormat?.length > 0 && (
                      <span className="sheduleDayDate"> {formattedDate}</span>
                    )}

                    <p className="sheduleDayHeading">{data.title}</p>
                    {!data.icon && (
                      <p
                        className="sheduleDayParagraph"
                        dangerouslySetInnerHTML={{
                          __html: `${data?.descriptions}`,
                        }}
                      ></p>
                    )}
                    {data.icon && (
                      <>
                        {" "}
                        <p
                          className="sheduleDayParagraph"
                          dangerouslySetInnerHTML={{
                            __html: `${data?.descriptions.substring(
                              0,
                              80
                            )}....`,
                          }}
                        ></p>
                        <span
                          onClick={() => {
                            setModalData(data?.descriptions);
                            largemodalShow();
                          }}
                          className="knowMore"
                          style={{ color: "#36b54a", marginTop: "-20px" }}
                        >
                          Know More
                        </span>
                        <LazyLoad height={isMobile ? 200 : 400} offset={50}>
                          <CardMedia
                            sx={{
                              height: `${isMobile ? "200px" : "400px"}`,
                              width: `${isMobile ? "300px" : "600px"}`,
                              borderRadius: "15px",
                              objectFit: "cover",
                            }}
                            image={data.icon}
                            title={data.title}
                          />
                        </LazyLoad>
                      </>
                    )}
                    <div
                      style={{
                        backgroundColor: "#5d646d",
                        width: "90%",
                        height: "0.5px",
                        marginTop: "15px",
                        marginBottom: "5px",
                      }}
                    ></div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </div>
  );
};
