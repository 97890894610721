import React, { useCallback, useState, useEffect } from "react";
// import Button from "@mui/material/Button";
// import { Icon as Iconfiy } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import Card from "@mui/material/Card";
// import Drawer from "@mui/material/Drawer";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import TestimonialCard from "material-testimonial-card";
import {
  getAllBlogs,
  getAllCities,
  getTripsByCategoryId,
  tripByCityId,
  tripByCityName,
} from "../api";
import { ToastContainer, toast } from "react-toastify";
// import FullCalendar from "@fullcalendar/react"; // must go before plugins
// import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import RevoCalendar from "revo-calendar";
import TripCardView from "../component/TripCardView";
import section2 from "../assets/section2.jpeg";
import SmallTripView from "../component/SmallTripView";
import moment from "moment";
import { useDispatch } from "react-redux";

import {
  updateUserInformation,
  updateUserInformationTotalFees,
} from "../redux/actions";
import { useSelector } from "react-redux";

export const Blogs_deials = () => {
  let { id, name } = useParams();

  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(Date.now());

  const userInfo = useSelector((state) => state.userInformation);

  const [eventsData, setEventsData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [tripsData, setTripsData] = useState([]);
  const getData = useCallback(async () => {
    setTripsData();
    setEventsData();
    try {
      const apiData = await getAllBlogs();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        let dataBlogs = apiData.data.filter((blogsdata) => {
          return blogsdata._id === id;
        });
        console.log("dataBlogs", dataBlogs);

        setMainData(dataBlogs);
      }
    } catch (err) {
      console.log("err", err.name);
      // if (err.response) {
      //   setError(err.response.data.message);
      //   setErrorToast(err.response.data.message);
      // } else if (err.name === "AxiosError") {
      //   setError(err.message);
      //   setErrorToast(err.message);
      // } else {
      //   setError("something went wrong");
      //   setErrorToast("something went wrong");
      // }
    }
    // setLoading(false);
  }, []);
  const getDataByDatesOfMonthNew = (dataArray, milliseconds) => {
    const filteredData = dataArray.filter((item) => {
      return item.cities.some((city) => {
        return (
          city.testName2 === userInfo.selectedCity &&
          city.monthData.some((month) => {
            return month.datesOfMonthNew.includes(milliseconds);
          })
        );
      });
    });
    return filteredData;
  };
  const getTripsData = useCallback(async () => {
    // setEventsData();
    try {
      let body = {
        cityName: userInfo.selectedCity,
      };
      const apiData = await tripByCityName(body);
      if (apiData.error) {
        setError(apiData.error);
      } else {
        console.log("getTripsData", apiData);
        const filteredData = getDataByDatesOfMonthNew(
          apiData.data,
          selectedDate
        );
        console.log("filteredData", filteredData);

        setTripsData(filteredData);
        const getUniqueValues = (array) => {
          const uniqueValues = [...new Set(array)];
          return uniqueValues.map((value) => ({
            date: value,
            allDay: true,
          }));
        };

        let trips = apiData.data;
        // Extract unique datesOfMonthNew values
        const uniqueDatesOfMonthNew = getUniqueValues(
          trips.flatMap((trip) =>
            trip.cities.flatMap((city) =>
              city.monthData.flatMap((month) => month.datesOfMonthNew)
            )
          )
        );
        // setEventsData()
        console.log("uniqueDatesOfMonthNew", uniqueDatesOfMonthNew);
        setEventsData(uniqueDatesOfMonthNew);
      }
    } catch (err) {
      console.log("err", err.name);
      // if (err.response) {
      //   setError(err.response.data.message);
      //   setErrorToast(err.response.data.message);
      // } else if (err.name === "AxiosError") {
      //   setError(err.message);
      //   setErrorToast(err.message);
      // } else {
      //   setError("something went wrong");
      //   setErrorToast("something went wrong");
      // }
    }
    // setLoading(false);
  }, [userInfo, selectedDate]);

  useEffect(() => {
    getData();
    //     getTripsData();
  }, [userInfo, selectedDate]);

  //   let id = "123";
  // const [mainData, setMainData] = useState([]);
  const [error, setError] = useState("");

  const convertDateToMilliseconds = (dateObject) => {
    const { day, month, year } = dateObject;
    const date = new Date(year, month + 1 - 1, day);
    return date.getTime();
  };
  const dateSelectedEvent = (title) => {
    console.log("title", title);
    let dateNew = convertDateToMilliseconds(title);
    setSelectedDate(dateNew);
  };
  var events = [
    // {
    //   name: "Buyout",
    //   date: Date.now(),
    //   allDay: true,
    // },
    {
      name: "Reservation",
      date: Date.now(),
      extra: {
        icon: "M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09           4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z",
        text: "7 People",
      },
    },
  ];
  useEffect(() => {
    //     console.log("useEffect called");
    //     getData();
  }, []);
  const navigate = useNavigate();

  const setErrorToast = (errorFromBackend) => {
    toast.error(
      <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
        Error: {error.length > 0 ? error : errorFromBackend}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
        theme: "colored",
      }
    );
  };
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100% !important",
          height: "168.8px",
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "flex-end",
          // alignItems: "flex-start",
          background: "#36b54a",
          // paddingLeft: "12%",
          // paddingBottom: "20px",
        }}
      >
        <h2 className="loginScreenTextBlog">Blogs</h2>
        {/* <ToastContainer /> */}
      </div>

      <div className="phoneNumberDivLogin">
        <section className="">
          <div className="">
            <div className="row">
              {mainData &&
                mainData.map((data, i) => {
                  return (
                    <>
                      <section className="">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="card-item blog-card blog-card-layout-2 blog-single-card mb-5">
                                <div className="card-img before-none">
                                  <img
                                    src={data?.image}
                                    style={{ width: "770px", height: "321px" }}
                                    alt="blog-img"
                                  />
                                </div>
                                <div className="card-body px-0 pb-0">
                                  <div className="post-categories">
                                    {/* <a href="#" className="badge">Travel</a>
                                                   <a href="#" className="badge">lifestyle</a> */}
                                  </div>
                                  <h3 className="card-title font-size-28">
                                    {data.title}
                                  </h3>
                                  <p className="card-meta pb-3">
                                    <span className="post__author">
                                      By{" "}
                                      <a href="#" className="text-gray">
                                        {data?.author}
                                      </a>
                                    </span>
                                    <span className="post-dot" />
                                    <span className="post__date">
                                      {" "}
                                      {data?.date}
                                    </span>
                                  </p>
                                  <div className="section-block" />
                                  <p className="card-text py-3 timelineULOL">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data?.description,
                                      }}
                                    ></div>
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* end col-lg-8 */}

                            {/* end col-lg-4 */}
                          </div>
                          {/* end row */}
                        </div>
                        {/* end container */}
                      </section>
                    </>
                  );
                })}
            </div>
            {/* end row */}

            {/* end row */}
          </div>
          {/* end container */}
        </section>
      </div>
    </div>
  );
};
