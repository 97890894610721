import { Icon as Iconfiy } from "@iconify/react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import useRazorpay from "react-razorpay";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { createBooking, updateBookingPayment } from "../api";
import scannerImage from "../assets/scanner.jpeg";
import { updateUserInformation } from "../redux/actions";
export const CustomerRegistration = () => {
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [partialAmount, setPartialAmount] = useState(0);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [customMessage, setCustomMessage] = useState(null);

  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  // const [userInfo, setParticipantsData] = useState([]);
  // useEffect(() => {
  //   console.log("userInfo?.participant on mount:", userInfo?.participant);
  // }, []);

  const handleCheckboxChange = (event) => {
    // console.log("event", event.target.checked);
    setIsChecked(event.target.checked);
    // if(event.target.checked === true)
    // {
    //   setIsChecked(false);
    // }
    // else
    // {
    //   setIsChecked(true);

    // }
  };

  const userInfo = useSelector((state) => state.userInformation);
  const [total_fees, setTotal_fees] = useState(
    parseFloat(userInfo?.total_fees?.toFixed(0))
  );
  console.log("total_fees", total_fees);
  // const [main_fees, setMain_fees] = useState(userInfo?.total_fees);

  const customer_id = localStorage.getItem("customer_id");
  const user_mobile = localStorage.getItem("user_mobile");
  const [largeShow, setlargeShow] = useState(false);

  const setlargeShowFunctionn = () => {
    setSelectedImage1("");
    setlargeShow(true);
  };
  const [bookingStatus, setBookingStatus] = useState("Confirmed");
  const [paymentType, setPaymentType] = useState("Full");
  const largemodalClose = () => setlargeShow(false);
  const largemodalShow = () => setlargeShow(true);
  const [finalAmountWIthPaymentGateWay, setFinalAmountWIthPaymentGateWay] =
    useState(0);
  const [razorpayFees, setRazorpayFees] = useState(0);
  const [razorpayFeesGst, setRazorpayFeesGstNew] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    if (!customer_id) {
      navigate("/");
    } else {
    }

    return setIsChecked(false);
  }, [navigate, customer_id]);
  const Razorpay = useRazorpay();

  useEffect(() => {
    // console.log("useEffect called", userInfo.trip_name);
    if (userInfo.trip_name === 0) {
      navigate("/");
    }
  }, [navigate, userInfo.trip_name]);

  useEffect(() => {
    const updatedInfo = {
      participant: [],
    };

    dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
      .then(() => {
        // console.log("participant cleared");
        // alert("hi");
        console.log("userinfoparticipant", userInfo);
      })
      .catch((error) => {
        // alert("Failed to update user information.");
      });
  }, []);

  const handleParticipantInputChange = (e, index, field) => {
    const { value } = e.target;

    const newData = [...(userInfo?.participant || [])];
    // const newData2 = [...userInfo?.participant];

    newData[index] = { ...newData[index], [field]: value };

    // setParticipantsData(newData2);
    const updatedInfo = {
      participant: newData,
    };

    dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
      .then(() => {
        // console.log("userinfoparticipant", userInfo?.participant);
      })
      .catch((error) => {
        // alert("Failed to update user information.");
      });
  };

  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const setErrorToast = (errorFromBackend) => {
    toast.error(
      <p className="text-white tx-16 mb-0">
        Error: {error.length > 0 ? error : errorFromBackend}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
        theme: "colored",
      }
    );
  };

  const participant_count = userInfo?.participant_count || 1;
  const isParticipantValid = (participant2) => {
    return (
      participant2?.firstName &&
      participant2?.lastName &&
      participant2?.email &&
      participant2?.mobile &&
      participant2?.dob &&
      participant2?.gender
    );
  };

  const trip_amount = userInfo?.trip_amount || 0;
  const tax_on_amount = 2.3;
  const addOnTotal =
    userInfo?.selectedAddOn?.length > 0
      ? userInfo?.selectedAddOn?.reduce((total, data) => {
          // console.log("data", data.total);
          return total + data.total;
        }, 0)
      : 0;

  const allParticipantsValid = userInfo?.participant?.every(isParticipantValid);
  // console.log("isParticipantValid", allParticipantsValid);
  const renderParticipants = () => {
    const participants = [];
    for (let i = 0; i < participant_count; i++) {
      participants.push(
        <div style={{ paddingTop: "20px" }}>
          <div className="form-group">
            <label htmlFor="" className="participantformlabel">
              {" "}
              Participant {i + 1} {i === 0 ? "(Primary)" : ""}
            </label>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="first_name" className="basicPartiCipant">
                  First Name
                </label>
                <input
                  type="text"
                  onChange={(e) =>
                    handleParticipantInputChange(e, i, "firstName")
                  }
                  placeholder="Enter First Name"
                  className="form-control inputTypeParticipant"
                  name="first_name"
                  autoComplete="on"
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="last_name" className="basicPartiCipant">
                  Last Name
                </label>
                <input
                  type="text"
                  onChange={(e) =>
                    handleParticipantInputChange(e, i, "lastName")
                  }
                  placeholder="Enter Last Name"
                  className="form-control inputTypeParticipant"
                  name="last_name"
                  autoComplete="on"
                />
              </div>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="email" className="basicPartiCipant">
                  Email
                </label>
                <input
                  type="email"
                  onChange={(e) => handleParticipantInputChange(e, i, "email")}
                  placeholder="Enter Email"
                  className="form-control inputTypeParticipant"
                  name="email"
                  autoComplete="on"
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="mobile" className="basicPartiCipant">
                  WhatsApp Number
                </label>
                <input
                  type="number"
                  onChange={(e) => handleParticipantInputChange(e, i, "mobile")}
                  placeholder="Enter WhatsApp Number"
                  className="form-control inputTypeParticipant"
                  name="mobile"
                  autoComplete="on"
                />
              </div>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="birth_date" className="basicPartiCipant">
                  Birth Date
                </label>
                <input
                  type="date"
                  onChange={(e) => handleParticipantInputChange(e, i, "dob")}
                  className="form-control inputTypeParticipant"
                  name="birth_date"
                  autoComplete="on"
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="" className="basicPartiCipant">
                  Select Gender
                </label>
                <select
                  className="form-control inputTypeParticipant"
                  onChange={(e) => handleParticipantInputChange(e, i, "gender")}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <hr />
            </div>
          </div>
        </div>
      );
    }
    return participants;
  };

  // const isHaveAllValues = userInfo?.participant.length > 0 && userInfo?.participant?.every(obj => {
  //   // Use Object.values to get an array of property values, then check if none of them are empty
  //   return Object.values(obj).every(value => value !== "");
  // });

  // console.log("isHaveAllValues",isHaveAllValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addData = async (paymentID,bookingDetails) => {
    setError("");
    setSuccessMessage("");

    setLoadingNew(true);
    const updatedNew = userInfo?.participant.map((data) => ({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      dob: data.dob,
      gender: data.gender,
      // Add other fields here as needed
    }));
    // console.log("userInfo?.participant", userInfo?.participant);
    // console.log("updateduserInfo?.participant", updatedNew);

    let partial_payment = [];
    if (paymentType === "Partial") {
      partial_payment = [
        {
          type: "Partial",
          partial_paymentId: "Offline",
          date: new Date(),
          time: new Date().toTimeString(),
          amount: partialAmount,
          paymentStatus: "Paid",
        },
        {
          type: "Partial",
          partial_paymentId: "",
          date: "",
          time: "",
          amount: Number(total_fees) - Number(partialAmount),
          paymentStatus: "Unpaid",
        },
      ];
    }

    if (updatedNew.length > 0 || true) {
      let currentDate = new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      let currentTime = new Date().toLocaleTimeString("en-US", {
        hour12: true, // Use 12-hour format
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      let trip_amount =  userInfo.trip_amount;
      if(userInfo?.promocodeAmount)
      {
        trip_amount = parseFloat(trip_amount) - parseFloat(userInfo?.promocodeAmount);
      }
      let body = {
        bookingData:bookingDetails,
        paymentId: paymentID.razorpay_payment_id,
        bookingStatus: "Confirmed",
      };
      console.log("body", body);
      try {
        const apiData = await updateBookingPayment(body);
        if (apiData.error) {
          setError(apiData.error);
        } else {
          navigate("/OrderPlaced");
          setLoadingNew(false);
          // toast.success(
          //   <p className="text-white tx-16 mb-0">Success : Trips Added !</p>,
          //   {
          //     position: toast.POSITION.TOP_RIGHT,
          //     hideProgressBar: false,
          //     autoClose: 3000,
          //     theme: "colored",
          //   }
          // );
        }
      } catch (err) {
        console.log("err123", err);
        if (err.response) {
          setError(err.response.data.message);
          setErrorToast(err.response.data.message);
        } else if (err.name === "AxiosError") {
          setError(err.message);
          setErrorToast(err.message);
        } else {
          setError("something went wrong");
          setErrorToast("something went wrong");
        }
      }

      setLoadingNew(false);

      // setLoading(false);
    } else {
      alert("participant data error !! please refresh page try again");
    }
  };

  const placeOrder = async () => {
    const updatedNew = userInfo?.participant.map((data) => ({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      dob: data.dob,
      gender: data.gender,
      // Add other fields here as needed
    }));
    let partial_payment = [];
    if (paymentType === "Partial") {
      partial_payment = [
        {
          type: "Partial",
          partial_paymentId: "Offline",
          date: new Date(),
          time: new Date().toTimeString(),
          amount: partialAmount,
          paymentStatus: "Paid",
        },
        {
          type: "Partial",
          partial_paymentId: "",
          date: "",
          time: "",
          amount: Number(total_fees) - Number(partialAmount),
          paymentStatus: "Unpaid",
        },
      ];
    }

    if (updatedNew.length > 0 || true) {
      let currentDate = new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      let currentTime = new Date().toLocaleTimeString("en-US", {
        hour12: true, // Use 12-hour format
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      let trip_amount =  userInfo.trip_amount;
      if(userInfo?.promocodeAmount)
      {
        trip_amount = parseFloat(trip_amount) - parseFloat(userInfo?.promocodeAmount);
      }
      let body = {
        trip_name: userInfo.trip_name,
        trip_id: userInfo.trip_id,
        trip_date: userInfo.trip_date,
        trip_city: userInfo.trip_city,
        city_id: userInfo.city_id,
        promocodeAmount: userInfo?.promocodeAmount,
        promocodeUsed: userInfo?.promocodeUsed,
        trip_amount: trip_amount,
        participant_count: userInfo.participant_count,
        participant: userInfo?.participant,
        total_fees: total_fees,
        main_fees: total_fees,
        transaction_charges: 0,
        discount: 0,
        paymentId: '',
        bookingDate: currentDate,
        bookingTime: currentTime,
        bookingType: "Website",
        bookingStatus: 'Unconfirmed',
        customer_name: updatedNew[0]?.firstName
          ? updatedNew[0]?.firstName + "" + updatedNew[0]?.lastName
          : "Testing",
        customer_mobile: user_mobile,
        customer_id: customer_id,
        icon: selectedImage1,
        partial_payment: partial_payment,
        addOnDetails: userInfo?.selectedAddOn,
        paymentType: paymentType,
        customMessage: customMessage,
        addONAmount:userInfo?.addONAmount,
        subTotalAmount:userInfo?.subTotalAmount,
        gstAmount:userInfo?.gstAmount,
        tcsAmount:userInfo?.tcsAmount,
        priceTitle:userInfo?.priceTitle
      };

      console.log("body", body);
      try {
        const apiData = await createBooking(body);
        if (apiData.error) {
          setError(apiData.error);
          return [];
        } else {
          return apiData.data;
        }
      } catch (err) {
        console.log("err123", err);
        if (err.response) {
          setError(err.response.data.message);
          setErrorToast(err.response.data.message);
        } else if (err.name === "AxiosError") {
          setError(err.message);
          setErrorToast(err.message);
        } else {
          setError("something went wrong");
          setErrorToast("something went wrong");
        }
       

      }
      setLoadingNew(false);
      return [];

      // setLoading(false);
    } else {
      alert("participant data error !! please refresh page try again");
    }
  }
  const handlePayment = useCallback((bookingDetails) => {
    // setLoadingNew(true);
    // navigate("/CustomerRegistration");
    
    const mainAmount = paymentType === "Full"
    ? Math.round(total_fees * 100)
    : Math.round(partialAmount * 100);
    const order = "test";
    const options = {
      key: "rzp_live_hZDLQmd8aKv4wj",
      // key: "rzp_test_cA6phebXWf464T",
      amount:
        paymentType === "Full"
          ? Math.round(total_fees * 100)
          : Math.round(partialAmount * 100),
      currency: "INR",
      name: "Trek Panda",
      description: userInfo.trip_name,
      image:
        "http://website.aanshulindia.in/static/media/logo.5342288cd312fec5f658.png",
      order_id: order.id,
      handler: (res) => {
        // console.log("handler", res);
        if (res.razorpay_payment_id) {
          setTimeout(() => {
            console.log("resresresresresres",res);
            addData(res,bookingDetails);
          }, 2000); // 2000 milliseconds (2 seconds)
        } else {
          // navigate("/OrderCancelled");
          navigate("/OrderCancelled");
          // alert("Error While adding payment");
        }
      },
      prefill: {
        name: `${userInfo?.participant[0]?.firstName} ${userInfo?.participant[0]?.lastName}`,
        email: `${userInfo?.participant[0]?.email}`,
        contact: `${userInfo?.participant[0]?.mobile}`,
      },
      notes: {
        trip_date: userInfo.trip_date,
        trip_city: userInfo.trip_city,
        priceTitle:userInfo?.priceTitle,
        participant_count:participant_count,
        invoice_number : bookingDetails?.invoice_number,
        customMessage: customMessage,
        addONAmount:userInfo?.addONAmount,
        subTotalAmount:userInfo?.subTotalAmount,
        gstAmount:userInfo?.gstAmount,
        tcsAmount:userInfo?.tcsAmount,
        final_amount:mainAmount,
        backend_id : bookingDetails?._id,
      },
      theme: {
        color: "#36b54a",
      },
      checkout: {
        method: {
          netbanking: 0,
          card: 1,
          upi: 0,
          wallet: 0,
        },
      },
    };

    const rzpay = new Razorpay(options);
    // console.log("rzpay", rzpay);
    rzpay.on("payment.failed", function (response) {
      navigate("/OrderCancelled");

      // alert("Error While doing payment,please try again");
    });
    rzpay.open();
  }, [
    Razorpay,
    addData,
    navigate,
    partialAmount,
    paymentType,
    total_fees,
    userInfo?.participant,
    userInfo.trip_name,
  ]);

  const handleOrderAndPayment = async () => {
    setLoadingNew(true); // Start loading before placing the order
    try {
      const bookingDetails = await placeOrder(); // Call placeOrder
      handlePayment(bookingDetails);    // Call handlePayment only after placeOrder is successful
    } catch (error) {
      console.error("Order failed:", error);
      // Optionally show an error to the user
    } finally {
      setLoadingNew(false); // Stop loading after the process is complete
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <ToastContainer />

      <div
        style={{
          width: "100%",
          height: "168.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        {/* <h2 className="loginScreenTextPay">Pay</h2> */}
      </div>

      {loadingNew ? (
        <>
          <div className="loading-container">
            <div className="loading">Loading...</div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div
            className="phoneNumberDivLoginRegistration padding12"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              backgroundColor: "red",
            }}
          >
            <p className="selectParticipantTitle">{userInfo?.trip_name}</p>
            <p
              className="selectParticipantTitle"
              style={{
                color: "#717171",
                fontSize: "1rem",
                lineHeight: "1.8",
                fontWeight: "500",
              }}
            >
              {userInfo?.trip_city} <br />
              {userInfo.trip_date}
            </p>
          </div>
          {/* {userInfo?.participant &&
            userInfo?.participant.map((data, i) => {
              return (
                <div>
                  {data?.firstName} {data?.email}
                </div>
              );
            })} */}
          {/* {userInfo && userInfo?.trip_amount > 0 && (
            <div className="padding12 mb-4">
              <p className="selectParticipantTitle">Select Payment Type:</p>
              <select
                name=""
                style={{ width: "200px" }}
                onChange={(e) => {
                  setPaymentType(e.target.value);
                  if (e.target.value === "Full") {
                    setTotal_fees(
                      parseFloat((userInfo?.total_fees).toFixed(2))
                    );
                    setPartialAmount(0);
                    setBookingStatus("Confirmed");
                  } else {
                    let new_partial_percentage = Number(
                      userInfo?.partialPayment
                    );
                    let new_total_amount =
                      (total_fees * new_partial_percentage) / 100;
                    // console.log("new_partial_percentage", new_partial_percentage);
                    // console.log("new_total_amount", new_total_amount);
                    setPartialAmount(parseFloat(new_total_amount.toFixed(2)));
                    // setTotal_fees(new_total_amount);
                    setBookingStatus("Unconfirmed");
                  }
                }}
                className="form-control"
                id=""
              >
                <option
                  selected={paymentType === "Full"}
                  defaultValue="Full"
                  value="Full"
                >
                  Full Payment
                </option>
                {userInfo?.partialPayment !== 0 && (
                  <option
                    selected={paymentType === "Partial"}
                    defaultValue="Partial"
                    value="Partial"
                  >
                    Partial Payment
                  </option>
                )}
              </select>
            </div>
          )} */}
          <div className="padding12 padding-right-8">
            <div style={{ paddingTop: "20px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="" className="participantformlabel">
                      Custom Message (if Any)
                    </label>
                    <textarea
                      type="text"
                      onChange={(e) => setCustomMessage(e.target.value)}
                      placeholder="Enter Custom Message"
                      className="form-control inputTypeParticipant"
                    />
                  </div>
                </div>
              </div>
            </div>

            {renderParticipants()}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              id="payment type"
            >
              {userInfo && userInfo?.trip_amount > 0 && (
                <div className=" mb-4">
                  <p className="selectParticipantTitle">Select Payment Type:</p>
                  <div
                    className="custom-radio-group"
                    onChange={(e) => {
                      setPaymentType(e.target.value);
                      if (e.target.value === "Full") {
                        setTotal_fees(
                          parseFloat(userInfo?.total_fees.toFixed(2))
                        );
                        setPartialAmount(0);
                        setBookingStatus("Confirmed");
                      } else {
                        let new_partial_percentage = Number(
                          userInfo?.partialPayment
                        );
                        let new_total_amount =
                          (total_fees * new_partial_percentage) / 100;
                        setPartialAmount(
                          parseFloat(new_total_amount.toFixed(2))
                        );
                        setBookingStatus("Confirmed");
                      }
                    }}
                  >
                    <label className="custom-radio">
                      <input
                        type="radio"
                        value="Full"
                        name="paymentType"
                        checked={paymentType === "Full"}
                      />
                      <span className="custom-radio-button"></span> Full Payment
                    </label>
                    {userInfo?.partialPayment !== 0 && (
                      <label className="custom-radio ml-2">
                        <input
                          type="radio"
                          value="Partial"
                          name="paymentType"
                          checked={paymentType === "Partial"}
                        />
                        <span className="custom-radio-button"></span> Partial
                        Payment
                      </label>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* {userInfo.participant?.map((data, index) => {
          return (
            <div style={{ paddingTop: "20px" }}>
              <div className="form-group">
                <label htmlFor="" className="participantformlabel">
                  {" "}
                  Participant {index + 1}
                </label>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="" className="basicPartiCipant">
                      First Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      className="form-control inputTypeParticipant"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="" className="basicPartiCipant">
                      Last Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      className="form-control inputTypeParticipant"
                    />
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="" className="basicPartiCipant">
                      Mobile Number
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Mobile Number"
                      className="form-control inputTypeParticipant"
                    />
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="" className="basicPartiCipant">
                      Birth Date
                    </label>
                    <input
                      type="date"
                      className="form-control inputTypeParticipant"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="" className="basicPartiCipant">
                      Select Gender
                    </label>
                    <select
                      className="form-control inputTypeParticipant"
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                  <hr />
                </div>
              </div>
            </div>
          );
        })} */}

            <FormControlLabel
              value="end"
              control={
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              }
              labelPlacement="end"
            />
            <span
              className="basicPartiCipant"
              style={{ fontWeight: "600", marginLeft: "-15px" }}
            >
              I accept participation terms & conditions
            </span>
            <a
              href="https://www.trekpanda.in/CancellationPolicy"
              target="_blank"
              className="basicPartiCipant"
              style={{ fontWeight: "600", marginLeft: "2px", color: "#36b54a" }}
              rel="noreferrer"
            >
              (View)
            </a>
            <br />
            {!isChecked && (
              <div className="alert alert-danger t_n_c_error">
                Please accept participation terms & conditions
              </div>
            )}
            <div id="razorpay-affordability-widget"> </div>
            <br />

            {/* <button
              onClick={() => {
                addData("abcdagta");
              }}
            >
              {" "}
              place order
            </button> */}

            <div>
              {userInfo?.participant?.length > 0 &&
              allParticipantsValid &&
              isChecked ? (
                <>
                  <Button
                    sx={{
                      marginTop: "16px",
                      width: "300.39px",
                      height: "38px",
                      background: "#36b54a",
                      border: "1px solid #36b54a",
                      color: "#FFFFFF",
                      // marginBottom:"5px",
                      padding: "7px 16.39px 7px 16px",

                      borderRadius: "9.6px",
                      fontFamily: "Montserrat",
                      fontWeight: "700",

                      // marginBottom:"10px"
                      // marginBottom:"-20px"
                    }}
                    className="button_hover" //#36b54ad4
                    onClick={handleOrderAndPayment}
                  >
                    Pay Online{" "}
                    {paymentType === "Full" ? total_fees : partialAmount} ₹
                    <Iconfiy
                      width="20"
                      height="28"
                      color="#fff"
                      icon="uil:arrow-right"
                    />{" "}
                  </Button>
                  {userInfo?.showOfflineBooking && (
                    <Button
                      sx={{
                        marginLeft: "20px",
                        marginTop: "16px",
                        width: "300.39px",
                        height: "38px",
                        background: "#36b54a",
                        border: "1px solid #36b54a",
                        color: "#FFFFFF",
                        // marginBottom:"5px",
                        padding: "7px 16.39px 7px 16px",

                        borderRadius: "9.6px",
                        fontFamily: "Montserrat",
                        fontWeight: "700",

                        // marginBottom:"10px"
                        // marginBottom:"-20px"
                      }}
                      // disabled={!isChecked}
                      onClick={() => {
                        setlargeShowFunctionn();
                      }}
                    >
                      Pay Offline{" "}
                      {paymentType === "Full" ? total_fees : partialAmount}
                      ₹
                      <Iconfiy
                        width="20"
                        height="28"
                        color="#fff"
                        icon="uil:arrow-right"
                      />{" "}
                    </Button>
                  )}
                </>
              ) : (
                <div className="alert alert-danger participants_details_error">
                  Please Enter All Participant Details to Continue Booking
                </div>
              )}
            </div>

            <br />
            <br />

            <br />
            <br />

            <Modal size="lg" show={largeShow} onHide={largemodalClose}>
              <ModalHeader>
                <ModalTitle> Pay Through UPI</ModalTitle>
                <span className="d-flex ms-auto" onClick={largemodalClose}>
                  <i className="fe fe-x ms-auto"></i>
                </span>
              </ModalHeader>
              <ModalBody>
                <div className="container">
                  <h5>
                    Amount To Pay <b>{total_fees}₹ </b>
                  </h5>
                  <br />
                  <h6>
                    Please Pay <b>{total_fees} ₹ </b> To Following UPI Details,
                    And Upload Screenshot Here{" "}
                  </h6>
                  <ol style={{ marginLeft: "20px", marginTop: "20px" }}>
                    <li>
                      UPI SCANNER : <img src={scannerImage} alt="" />
                    </li>
                    <li style={{ marginTop: 10 }}>
                      UPI ID : TREKPANDAINDIALLP.eazypay@icici
                    </li>
                    <li style={{ marginTop: 10 }}>
                      <b> Note :</b> It Will Take 24 Hours to get Confirmation
                    </li>
                  </ol>

                  <div className="form-group">
                    <label htmlFor="">
                      Upload Screenshot <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      required
                      accept="image/*"
                      onChange={(event) => {
                        // console.log("change image", event.target.files[0]);
                        setSelectedImage1(event.target.files[0]);
                      }}
                    />
                    {/* <input type="file" className="form-control" name="" id="" /> */}
                  </div>
                  <div className="form-group">
                    {selectedImage1?.name?.length > 0 ? (
                      <Button
                        sx={{
                          marginTop: "16px",
                          width: "250.39px",
                          height: "38px",
                          background: "#36b54a",
                          border: "1px solid #36b54a",
                          color: "#FFFFFF",
                          // marginBottom:"5px",
                          padding: "7px 16.39px 7px 16px",

                          borderRadius: "9.6px",
                          fontFamily: "Montserrat",
                          fontWeight: "700",

                          // marginBottom:"10px"
                          // marginBottom:"-20px"
                        }}
                        onClick={() => {
                          addData("offline");
                        }}
                      >
                        Confirm Booking
                        <Iconfiy
                          width="20"
                          height="28"
                          color="#fff"
                          icon="uil:arrow-right"
                        />{" "}
                      </Button>
                    ) : (
                      <div className="alert-danger">
                        Please Upload Payment Screen First
                      </div>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button variant="secondary" onClick={largemodalClose}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};
