import React, { useState, useEffect, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Autoplay,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Skeleton from "@mui/material/Skeleton";

// import slider1 from "../assets/n1.jpeg";
// import slider2 from "../assets/n2.jpeg";
// import slider3 from "../assets/n3.jpeg";
// import slider4 from "../assets/n4.jpeg";
import test from "../assets/instagram.svg";
import test2 from "../assets/google.svg";
import test3 from "../assets/trips.svg";
import test4 from "../assets/test4.svg";
import { TypeAnimation } from "react-type-animation";
import { ToastContainer, toast } from "react-toastify";
import { getAllCities, getSliderImages } from "../api";
import { updateUserInformation } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

export const HomPageSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInformation);

  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [imgaes, setMainData] = useState([]);
  const [cities, setCities] = useState([]);
  const [error, setError] = useState("");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const apiData = await getSliderImages();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getData", apiData);
        setMainData(apiData.data);
      }
      const apiData2 = await getAllCities();
      if (apiData2.error) {
        setError(apiData2.error);
      } else {
        // console.log("getData", apiData2);
        setCities(apiData2.data);
      }
    } catch (err) {
      console.log("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        setErrorToast(err.response.data.message);
        navigate(`/errorPage/${err.response.data.message}`);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        setErrorToast(err.message);
        navigate(`/errorPage/${err.message}`);
      } else {
        setError("something went wrong");
        setErrorToast("something went wrong");
      }
    }
    setLoading(false);
  }, []);

  const setErrorToast = (errorFromBackend) => {
    toast.error(
      <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
        Error: {error.length > 0 ? error : errorFromBackend}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
        theme: "colored",
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  // const imgaes = [slider1, slider2, slider3, slider4];
  const [marginLeft, setMarginLeft] = useState("70px");
  const pointsList = [
    {
      name: "304k+\n Followers",
      vale: "304k+",
      icon: test,
      link: "http://instagram.com/trek_panda",
    },
    {
      name: "9118+  Reviews",
      vale: "9118",
      icon: test2,
      link: "https://maps.app.goo.gl/NybgiCGCRHEFsESk8",
    },
    {
      name: "4500+  Trips",
      vale: "4500+",
      icon: test3,
    },
    {
      name: "Participants",
      vale: "100000+",
      icon: test4,
    },
  ];
  const updateCity = (city) => {
    // setSelectedcity(city);
    const updatedInfo = {
      selectedCity: city,
    };

    dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
      .then(() => {
        const violation = document.getElementById("backendSectionsData");
        window.scrollTo({
          top: violation.offsetTop,
          behavior: "smooth",
        });
        // const customer_id = localStorage.getItem("customer_id");
        // if (customer_id) {
        //   navigate("/customerPayNowScreen");
        // } else {
        //   navigate("/CustomerLogin");
        // }
      })
      .catch((error) => {
        alert("Failed to update user information.");
        // Handle error scenarios if needed
      });
  };
  return (
    <>
      <ToastContainer />{" "}
      <div className="containerNew">
        {imageLoading ? (
          <Skeleton
            variant="rectangular"
            height={520}
            width="100%"
            className="containerNew"
            animation="wave"
          />
        ) : (
          ""
        )}
        <Swiper
          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
            EffectFade,
            Autoplay,
          ]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          style={{ background: "black", height: "100% !important" }}
        >
          {imgaes &&
            imgaes.map((data, i) => {
              if (data?.typeOfImage === "singleImage") {
                return null;
              }
              return (
                <SwiperSlide key={i}>
                  <img
                    style={{
                      opacity: 0.7,
                      height: "620px",
                      width: "100%",
                      objectFit: "cover",
                      background: "rgba(0, 0, 0, 0.5",
                      display: imageLoading ? "none" : "block",
                    }}
                    src={data.icon}
                    alt="Third slide"
                    className="img-fluid"
                    onLoad={() => setImageLoading(false)}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>

        <div className="zindexclass5 centeredImg  ">
          <span className="maintext">Experience</span>

          <br />
          <span>
            <TypeAnimation
              style={{
                // height: "200px",
                width: "300px",
                display: "block",
                // border: 'solid 1px rgb(0,0,0)',
                textAlign: "left",
                // backgroundColor:"green",
                marginBottom: "-15px",
              }}
              className="bigText"
              sequence={[
                "Adventure", // Types 'One'
                1500, // Waits 1s

                "Nature", // Deletes 'One' and types 'Two'
                1500, // Waits 2s

                "Thrill", // Types 'Three' without deleting 'Two'
                1500, // Waits 2s

                "Peace", // Types 'Three' without deleting 'Two'
                1500, // Waits 2s

                "Excitement", // Types 'Three' without deleting 'Two'
                2000, // Waits 2s

                () => {},
              ]}
              repeat={Infinity}
            />
          </span>
          <br />
          {/* <div
            className="whiteButton"
            style={{ border: "1px solid #ffffff", borderRadius: "9.6px" }}
          > */}
          {/* {} */}
          {/* Select city options */}
          {/* <select
            style={{
              border: "1px solid #ffffff",
              borderRadius: "9.6px",
              width: "50%",
            }}
            className="form-control"
            onChange={(e) => {
              updateCity(e.target.value);
            }}
          >
            {cities &&
              cities.map((data, i) => {
                return (
                  <option
                    key={i}
                    defaultChecked={userInfo?.selectedCity === data?.name}
                    value={data?.name}
                  >
                    {data?.name}
                  </option>
                );
              })}
          </select> */}
          {/* </div> */}
        </div>

        <div
          className="zindexclass5  bottomLeft text-center"
          style={{ marginBottom: "-20px" }}
        >
          {/* <span className="text-white bottomText">
            India's Largest Trekking Organization
          </span>
          <br /> */}
          <div
            className="container-fluid"
            // style={{
            //   display: "flex",
            //   flexDirection: "row",
            //   justifyContent: "space-between",
            //   flexWrap: "wrap",
            //   // zIndex:-2000000
            //   // padding: "10px"
            // }}
          >
            <div className="row">
              {pointsList &&
                pointsList.map((data, i) => {
                  return (
                    <div
                      key={i}
                      className="pointListWithoutMargin col-lg-3 col-6 col-md-4"
                      style={
                        {
                          // zIndex: -1000,
                          //   alignContent:"center",
                          //   alignItems:"center",
                          //  height:"90px",
                          //  width:"100px"
                          // flexWrap:'wrap'
                        }
                      }
                    >
                      <a href={data.link} target="_blank" rel="noreferrer">
                        <img
                          src={data.icon}
                          style={{ height: 50, width: 50 }}
                          alt=""
                        />
                      </a>
                      <a href={data.link} target="_blank" rel="noreferrer">
                        <span className="text-white">{data.name}</span>
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
