import { useEffect } from 'react';
import './AnnouncementBar.css'; // Import your CSS file here

const AnnouncementBar = () => {
  return (
    <div className="announcement-bar">
      <p className="announcement-text">
        Our new website is live! Visit now for an enhanced experience. You can still access the old website at <a href="http://trekpanda.co.in" target="_blank" rel="noopener noreferrer">trekpanda.co.in</a>
      </p>
    </div>
  );
};

export default AnnouncementBar;
