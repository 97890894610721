import React, { useCallback, useEffect, useState } from "react";
import {
  Image,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalFooter
} from "react-bootstrap";
import { createEnquiry, getContacts } from "../api";
import { toast } from "react-toastify";
import requestCallback from "../assets/requestCallback.svg"
import whatsapp from "../assets/whatsapp.svg";
import callNow from "../assets/callNow.png"
import instagram from "../assets/instagram.png"
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const WhatsappBtn = () => {
  const [largeShow, setLargeShow] = useState(false);
  const [contactModalState, setContactModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState(false);
  const [error, setError] = useState("");

  const [contactUsModal, setcontactUsModal] = useState(false);
  const showContactUsModal = () => setcontactUsModal(true);
  const hideContactUsModal = () => setcontactUsModal(false);

  const navigate = useNavigate();

  const setErrorToast = useCallback(
    (errorFromBackend) => {
      toast.error(
        <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
          Error: {error.length > 0 ? error : errorFromBackend}
        </p>,
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 2000,
          theme: "colored",
        }
      );
    },
    [error]
  );

  const modalClose = () => setLargeShow(false);
  const modalShow = () => {
    setLargeShow(true);
  };

  const contactModalClose = () => setContactModalState(false);
  const contactModalShow = () => setContactModalState(true);

  const formsSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const mobileInput = e.target.mobile.value;
    const emailInput = e.target.email.value;
  
    // Mobile validation: check if it has exactly 10 digits
    const mobilePattern = /^[0-9]{10}$/;
    if (!mobilePattern.test(mobileInput)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }
  
    // Email validation: standard email format check
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(emailInput)) {
      alert("Please enter a valid email address.");
      return;
    }
    // const contactPersonId = returnContactPersonFromTrip();
    formData.append("contactPersonId", '6452524a4bbe80ea2351ae38');
    // console.log("formData", formData);
    const formJson = Object.fromEntries(formData.entries());
    addData(formJson);
    setLoading(false);
  };


  
  const addData = async (body) => {
    setError("");
    // setSuccessMessage("");

    setLoading(true);

    try {
      const apiData = await createEnquiry(body);
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // getData();
        // handleClose3();
        // alert("Response Submitted");
        toast.success(
          <p className="text-white tx-16 mb-0">
            Success : Enquiry Submitted !
          </p>,
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: false,
            autoClose: 3000,
            theme: "colored",
          }
        );
      }
    } catch (err) {
      console.error("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        // setErrorToast(err.response.data.message);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        // setErrorToast(err.message);
      } else {
        setError("something went wrong");
        // setErrorToast("something went wrong");
      }
    }

    hideContactUsModal();
    window.scrollTo({ top: 0, left: 0 });
    setLoading(false);
  };

  const openEnquiryForm = () => {
    modalClose(); // Close the current modal (Contact Modal)
    showContactUsModal(); // Open the Enquiry Form Modal
  };
  

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const apiData = await getContacts();
        if (apiData.error) {
          setError(apiData.error);
        } else {
          // console.log("getContacts", apiData);
          setContacts(apiData.data);
        }
      } catch (err) {
        console.log("err", err.name);
        if (err.response) {
          setError(err.response.data.message);
          setErrorToast(err.response.data.message);
          navigate(`/errorPage/${err.response.data.message}`);
        } else if (err.name === "AxiosError") {
          setError(err.message);
          setErrorToast(err.message);
          navigate(`/errorPage/${err.message}`);
        } else {
          setError("something went wrong");
          setErrorToast("something went wrong");
        }
      }
      setLoading(false);
    };
    getData();
  }, [navigate, setErrorToast]);

  return (
    <>
      <div className="whatsappBtn">
        <img src="whatsapp.png" alt="whatsapp" onClick={modalShow} />
      </div>
      <Modal size="sm" show={largeShow} onHide={modalClose}>
        <ModalHeader>
          <ModalTitle>Contact</ModalTitle>
          <span
            style={{ cursor: "pointer" }}
            className="d-flex ms-auto"
            onClick={modalClose}
          >
            {/* <i className="fe fe-x ms-auto"></i> */}X
          </span>
        </ModalHeader>
        <ModalBody>
          {/* <span style={{cursor:"pointer"}} onclick={()=>{
            // contactModalClose();
            modalClose();
            // modalClose();
            // showContactUsModal();
          }}> */}
            <Image src={requestCallback} loading="lazy" height="50px" />
            <span onclick={()=>{
            // contactModalClose();
            modalClose();
            // modalClose();
            // showContactUsModal();
          }} style={{ paddingLeft: "20px",cursor:"pointer" }}>Request a Callback2</span>
            <hr />
          {/* </span> */}
          <Link to={`https://wa.me/918447937907`} target="_blank">
            <Image src={whatsapp} loading="lazy" height="50px" />
            <span style={{ paddingLeft: "20px" }}>Whatsapp</span>
            <hr />
          </Link>
          <Link onClick={()=>{contactModalShow();modalClose();}}>
            <Image
              src={callNow}
              loading="lazy"
              style={{ height: "50px", filter: "invert(1)" }}
            />
            <span style={{ paddingLeft: "20px" }}>Call Now</span>
            <hr />
          </Link>
          <Link
            to={`https://www.instagram.com/trek_panda/?hl=en`}
            target="_blank"
          >
            <Image src={instagram} loading="lazy" height="50px" />
            <span style={{ paddingLeft: "20px" }}>Instagram</span>
          </Link>
          {/* {contacts && contacts?.map((phoneNosData, i)=>{
                return (
                  <div key={i}>
                    {loading ? (
                      <Skeleton
                      key={i}
                        variant="rectangular"
                        sx={{ marginTop: "5px" }}
                        // width={450}
                        height={100}
                      />
                    ) : (
                      <>
                        <div key={i}>
                          <p>{phoneNosData?.title}</p>
                          <a href={`tel:${phoneNosData?.contactNum1}`}>
                            {phoneNosData?.contactNum1}
                          </a>{" "}
                          <br />
                          <a href={`tel:${phoneNosData?.contactNum2}`}>
                            {phoneNosData?.contactNum2}
                          </a>
                        </div>
                        <hr />
                      </>
                    )}
                  </div>
                );
            })} */}

          {/* <div>
            <p></p>
            <a href="tel:918447937907"></a>
          </div>
          <hr />
          <div>
            <p></p>
            <a href="tel:+91- 8669021690">+91- </a>
          </div>
          <hr />
          <div>
            <p></p>
            <a href="tel:+91- 7020216734">+91- </a>
          </div> */}
        </ModalBody>
      </Modal>
      {/* Contact Modal */}
      <Modal size="md" show={contactModalState} onHide={()=>{contactModalClose();modalShow();}}>
        <ModalHeader>
          <ModalTitle>Contact2</ModalTitle>
          <span
            style={{ cursor: "pointer" }}
            className="d-flex ms-auto"
            onClick={()=>{contactModalClose();modalShow();}}
          >
            {/* <i className="fe fe-x ms-auto"></i> */}X
          </span>
        </ModalHeader>
        <ModalBody>
          {contacts &&
            contacts?.map((phoneNosData, i) => {
              return (
                <div key={i}>
                  {loading ? (
                    <Skeleton
                      key={i}
                      variant="rectangular"
                      sx={{ marginTop: "5px" }}
                      // width={450}
                      height={100}
                    />
                  ) : (
                    <>
                      <div key={i}>
                        <p>{phoneNosData?.title}</p>
                        <a href={`tel:${phoneNosData?.contactNum1}`}>
                          {phoneNosData?.contactNum1}
                        </a>{" "}
                        <br />
                        <a href={`tel:${phoneNosData?.contactNum2}`}>
                          {phoneNosData?.contactNum2}
                        </a>
                      </div>
                      <hr />
                    </>
                  )}
                </div>
              );
            })}
        </ModalBody>
      </Modal>

      <Modal
                    size="lg"
                    show={contactUsModal}
                    onHide={hideContactUsModal}
                  >
                    <ModalHeader>
                      <ModalTitle>Enquiry Form</ModalTitle>
                      <span
                        style={{ cursor: "pointer" }}
                        className="d-flex ms-auto"
                        onClick={hideContactUsModal}
                      >
                        <i className="fe fe-x ms-auto"></i>
                      </span>
                    </ModalHeader>
                    <form
                      method="post"
                      onSubmit={formsSubmit}
                      // autocomplete="off"
                      encType="multipart/form-data"
                    >
                      <ModalBody style={{ fontFamily: "Montserrat" }}>
                        <div className="form-group">
                          <label htmlFor="">
                            Name <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            required
                            name="name"
                            placeholder="Enter First Name And Last Name"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">
                            Mobile <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="number"
                            required
                            name="mobile"
                            placeholder="Enter Mobile Number"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">
                            Email <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="email"
                            required
                            name="email"
                            placeholder="Enter Email"
                            className="form-control"
                          />
                          <input
                            type="hidden"
                            required
                            name="trip"
                            value={'home screen'}
                            placeholder="Enter Email"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">
                            Message <span className="text-danger">*</span>{" "}
                          </label>
                          <textarea
                            type="text"
                            required
                            name="message"
                            placeholder="Enter Your Message"
                            className="form-control"
                          ></textarea>
                        </div>
                      </ModalBody>

                      <ModalFooter>
                        <button className="btn btn-success" type="submit">
                          Submit
                        </button>
                        <button
                          className="btn btn-danger"
                          variant="secondary"
                          onClick={hideContactUsModal}
                        >
                          Close
                        </button>
                      </ModalFooter>
                    </form>
                  </Modal>

                  <Modal size="sm" show={largeShow} onHide={modalClose}>
  <ModalHeader>
    <ModalTitle>Contact</ModalTitle>
    <span
      style={{ cursor: "pointer" }}
      className="d-flex ms-auto"
      // onClick={modalClose}
    >
      {/* <i className="fe fe-x ms-auto"></i> */}X
    </span>
  </ModalHeader>
  <ModalBody>
    <span
      style={{ cursor: "pointer" }}
      onClick={() => {
        openEnquiryForm(); // Call function to open Enquiry Form
      }}
    >
      <Image src={requestCallback} loading="lazy" height="50px" />
      <span style={{ paddingLeft: "20px" }}>Request a Callback</span>
      <hr />
    </span>
    <Link to={`https://wa.me/918447937907`} target="_blank">
      <Image src={whatsapp} loading="lazy" height="50px" />
      <span style={{ paddingLeft: "20px" }}>Whatsapp</span>
      <hr />
    </Link>
    <Link
      onClick={() => {
        contactModalShow();
        modalClose();
      }}
    >
      <Image
        src={callNow}
        loading="lazy"
        style={{ height: "50px", filter: "invert(1)" }}
      />
      <span style={{ paddingLeft: "20px" }}>Call Now</span>
      <hr />
    </Link>
    <Link
      to={`https://www.instagram.com/trek_panda/?hl=en`}
      target="_blank"
    >
      <Image src={instagram} loading="lazy" height="50px" />
      <span style={{ paddingLeft: "20px" }}>Instagram</span>
    </Link>
  </ModalBody>
</Modal>

<Modal
  size="lg"
  show={contactUsModal}
  onHide={hideContactUsModal}
>
  <ModalHeader>
    <ModalTitle>Enquiry Form</ModalTitle>
    <span
      style={{ cursor: "pointer" }}
      className="d-flex ms-auto"
      onClick={hideContactUsModal}
    >
      <i className="fe fe-x ms-auto"></i>
    </span>
  </ModalHeader>
  <form method="post" onSubmit={formsSubmit} encType="multipart/form-data">
    <ModalBody style={{ fontFamily: "Montserrat" }}>
      <div className="form-group">
        <label htmlFor="">
          Name <span className="text-danger">*</span>{" "}
        </label>
        <input
          type="text"
          required
          name="name"
          placeholder="Enter First Name And Last Name"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="">
          Mobile <span className="text-danger">*</span>{" "}
        </label>
        <input
          type="number"
          required
          name="mobile"
          pattern="[0-9]{10}"  // Pattern for exactly 10 digits
          placeholder="Enter Mobile Number"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="">
          Email <span className="text-danger">*</span>{" "}
        </label>
        <input
          type="email"
          required
          name="email"
          placeholder="Enter Email"
          className="form-control"
        />
        <input
          type="hidden"
          required
          name="trip"
          value={'home screen'}
          placeholder="Enter Email"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="">
          Message <span className="text-danger">*</span>{" "}
        </label>
        <textarea
          type="text"
          required
          name="message"
          placeholder="Enter Your Message"
          className="form-control"
        ></textarea>
      </div>
    </ModalBody>
    <ModalFooter>
      <button className="btn btn-success" type="submit">
        Submit
      </button>
      <button
        className="btn btn-danger"
        variant="secondary"
        onClick={hideContactUsModal}
      >
        Close
      </button>
    </ModalFooter>
  </form>
</Modal>
    </>
  );
};

export default WhatsappBtn;
