/* eslint-disable jsx-a11y/alt-text */
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Banner.css"; // Make sure to create a corresponding CSS file for styling

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// import slider1 from '../assets/sliderNew4.png'
// import slider2 from '../assets/sliderNew4.png'
// import slider3 from '../assets/sliderNew4.png'
// import slider4 from '../assets/sliderNew4.png'
// import placeholder from '../assets/1440x300.png'
export const EventDetailSlider = (props) => {
  // const [loading, setLoading] = useState(false);

  const backendImages = props.images;
  const title = props.title;
  // console.log("backendImages", backendImages);
  // console.log("backendImages",backendImages);
  // const sliderItems = [
  //   { icon: <iframe
  //     width="100%"
  //     height="400"
  //     src="https://www.youtube.com/embed/6CplA7Fxcj8"
  //     title="YouTube video player"
  //     frameborder="0"
  //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  //     allowfullscreen
  //   ></iframe>, url: "" },
  //   { icon: <img
  //     style={{width:"100%",height:"400px",objectFit: "cover"}}
  //     src={slider4}

  //     alt="Third slide"
  //   />, url: "" },
  //   { icon: <img
  //     style={{width:"100%",height:"400px",objectFit: "cover"}}
  //     src={slider2}

  //     alt="Third slide"
  //   />, url: "" },
  //   { icon: <img
  //     style={{width:"100%",height:"400px",objectFit: "cover"}}
  //     src={slider3}

  //     alt="Third slide"
  //   />, url: "" },
  //   { icon: <img
  //     style={{width:"100%",height:"400px",objectFit: "cover"}}
  //     src={slider1}

  //     alt="Third slide"
  //   />, url: "" },
  //   { icon: <img
  //     style={{width:"100%",height:"400px",objectFit: "cover"}}
  //     src={slider4}

  //     alt="Third slide"
  //   />, url: "" },
  //   { icon: <img
  //     style={{width:"100%",height:"400px",objectFit: "cover"}}
  //     src={slider3}

  //     alt="Third slide"
  //   />, url: "" },

  // ];
  const detailImage =
    backendImages &&
    backendImages.find((data) => data?.type === "Detail Screen");
  const headerImage =
    backendImages &&
    backendImages.find((data) => data?.type === "Header Image");

  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      // navigation
      pagination={{ clickable: true }}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log('slide change')}
      height="400px"
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
    >
      {/* {backendImages && backendImages.map((data,i)=>{
          if(data?.type === "Detail Screen")
          {
            return (
              <SwiperSlide key={i}>
                <img
                  style={{ width: "100%", height: "400px", objectFit: "cover" }}
                  src={data.link}
                  key={i}
                  alt={`${i} slide`}
                />
                <div className="text-container">
                  <h1>Thailand Full Moon Party</h1>
                  <p>I Came, I Saw, I Captured</p>
                </div>
              </SwiperSlide>
            );

          }
          return null;

        })} */}

      <SwiperSlide>
        <img
          style={{
            width: "100%",
            height: "400px",
            objectFit: "cover",
            opacity: 1,
            boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // This adds a semi-transparent black overlay
            mixBlendMode: "darken", // This blend mode darkens the image below
          }}
          src={headerImage ? headerImage?.link : detailImage?.link}
          // key={i}
          // alt={`${i} slide`}
        />
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            mixBlendMode: "darken",
          }}
        ></div>
        <div className="text-container">
          <h1>{title}</h1>
          {/* <p>I Came, I Saw, I Captured</p> */}
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
