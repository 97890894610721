import React, { useCallback, useState, useEffect } from "react";
// import Button from "@mui/material/Button";
// import { Icon as Iconfiy } from "@iconify/react";
import { Link} from "react-router-dom";
// import Card from "@mui/material/Card";
// import Drawer from "@mui/material/Drawer";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import TestimonialCard from "material-testimonial-card";
import {
  getAllBlogs,
} from "../api";
import { ToastContainer } from "react-toastify";
// import FullCalendar from "@fullcalendar/react"; // must go before plugins
// import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
// import RevoCalendar from "revo-calendar";
// import TripCardView from "../component/TripCardView";
// import section2 from "../assets/section2.jpeg";
// import SmallTripView from "../component/SmallTripView";
// import moment from "moment";
// import { useDispatch } from "react-redux";

// import {
//   updateUserInformation,
//   updateUserInformationTotalFees,
// } from "../redux/actions";
import { useSelector } from "react-redux";

export const Blogs = () => {
  // const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(Date.now());

  const userInfo = useSelector((state) => state.userInformation);

  const [eventsData, setEventsData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [tripsData, setTripsData] = useState([]);
  const getData = useCallback(async () => {
    setTripsData();
    setEventsData();
    try {
      const apiData = await getAllBlogs();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getAllCities", apiData);
        setMainData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
      // if (err.response) {
      //   setError(err.response.data.message);
      //   setErrorToast(err.response.data.message);
      // } else if (err.name === "AxiosError") {
      //   setError(err.message);
      //   setErrorToast(err.message);
      // } else {
      //   setError("something went wrong");
      //   setErrorToast("something went wrong");
      // }
    }
    // setLoading(false);
  }, []);
  // const getDataByDatesOfMonthNew = (dataArray, milliseconds) => {
  //   const filteredData = dataArray.filter((item) => {
  //     return item.cities.some((city) => {
  //       return (
  //         city.testName2 === userInfo.selectedCity &&
  //         city.monthData.some((month) => {
  //           return month.datesOfMonthNew.includes(milliseconds);
  //         })
  //       );
  //     });
  //   });
  //   return filteredData;
  // };
  // const getTripsData = useCallback(async () => {
  //   // setEventsData();
  //   try {
  //     let body = {
  //       cityName: userInfo.selectedCity,
  //     };
  //     const apiData = await tripByCityName(body);
  //     if (apiData.error) {
  //       setError(apiData.error);
  //     } else {
  //       // console.log("getTripsData", apiData);
  //       const filteredData = getDataByDatesOfMonthNew(
  //         apiData.data,
  //         selectedDate
  //       );
  //       // console.log("filteredData", filteredData);

  //       setTripsData(filteredData);
  //       const getUniqueValues = (array) => {
  //         const uniqueValues = [...new Set(array)];
  //         return uniqueValues.map((value) => ({
  //           date: value,
  //           allDay: true,
  //         }));
  //       };

  //       let trips = apiData.data;
  //       // Extract unique datesOfMonthNew values
  //       const uniqueDatesOfMonthNew = getUniqueValues(
  //         trips.flatMap((trip) =>
  //           trip.cities.flatMap((city) =>
  //             city.monthData.flatMap((month) => month.datesOfMonthNew)
  //           )
  //         )
  //       );
  //       // setEventsData()
  //       // console.log("uniqueDatesOfMonthNew", uniqueDatesOfMonthNew);
  //       setEventsData(uniqueDatesOfMonthNew);
  //     }
  //   } catch (err) {
  //     console.log("err", err.name);
  //     // if (err.response) {
  //     //   setError(err.response.data.message);
  //     //   setErrorToast(err.response.data.message);
  //     // } else if (err.name === "AxiosError") {
  //     //   setError(err.message);
  //     //   setErrorToast(err.message);
  //     // } else {
  //     //   setError("something went wrong");
  //     //   setErrorToast("something went wrong");
  //     // }
  //   }
  //   // setLoading(false);
  // }, [userInfo, selectedDate]);

  useEffect(() => {
    getData();
    //     getTripsData();
  }, [userInfo, selectedDate, getData]);

  // let id = "123";
  // const [mainData, setMainData] = useState([]);
  const [error, setError] = useState("");

  // const convertDateToMilliseconds = (dateObject) => {
  //   const { day, month, year } = dateObject;
  //   const date = new Date(year, month + 1 - 1, day);
  //   return date.getTime();
  // };
  // const dateSelectedEvent = (title) => {
  //   // console.log("title", title);
  //   let dateNew = convertDateToMilliseconds(title);
  //   setSelectedDate(dateNew);
  // };
  // var events = [
  //   // {
  //   //   name: "Buyout",
  //   //   date: Date.now(),
  //   //   allDay: true,
  //   // },
  //   {
  //     name: "Reservation",
  //     date: Date.now(),
  //     extra: {
  //       icon: "M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09           4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z",
  //       text: "7 People",
  //     },
  //   },
  // ];
  useEffect(() => {
    //     console.log("useEffect called");
    //     getData();
  }, []);
  // const navigate = useNavigate();

  // const setErrorToast = (errorFromBackend) => {
  //   toast.error(
  //     <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
  //       Error: {error.length > 0 ? error : errorFromBackend}
  //     </p>,
  //     {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //       autoClose: 2000,
  //       theme: "colored",
  //     }
  //   );
  // };
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "168.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        <h2 className="loginScreenText">Blogs</h2>
        <ToastContainer />
      </div>

      <div className="phoneNumberDivLogin">
        <section className="card-area section--padding">
          <div className="container">
            <div className="row">
              {mainData &&
                mainData.map((data, i) => {
                  return (
                    <>
                      <div className="col-lg-4 responsive-column">
                        <div className="card-item blog-card">
                          <div className="card-img">
                            <img
                              src={data.image}
                              style={{ width: "100%", height: "247px" }}
                              loading="lazy"
                              alt={data.title}
                            />
                            <div className="post-format icon-element">
                              <i className="la la-photo" />
                            </div>
                            <div className="card-body">
                             
                              <h3 className="card-title line-height-26">
                                <Link to={`/blogs_details/${data._id}`}>
                                  {data.title}
                                </Link>
                              </h3>
                              <p className="card-meta">
                                <span className="post__date">{data.date}</span>
                              
                              </p>
                            </div>
                          </div>
                          <div className="card-footer d-flex align-items-center justify-content-between">
                            <div className="author-content d-flex align-items-center">
                              <div className="author-img">
                                <img
                                  src="images/small-team1.jpg"
                                  loading="lazy"
                                  alt={data.title}
                                />
                              </div>
                              <div className="author-bio">
                                <Link
                                  to={`/blogs_details/${data._id}`}
                                  className="author__title"
                                >
                                  {data?.author}
                                </Link>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            {/* end row */}

            {/* end row */}
          </div>
          {/* end container */}
        </section>
      </div>
    </div>
  );
};
