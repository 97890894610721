import { Icon as Iconfiy } from "@iconify/react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import RevoCalendar from "revo-calendar";
import { getTripsByCategoryId, getTripsByCategoryIdNew } from "../api";
import animation from "../assets/animation_cal.gif";
import TripCardView from "../component/TripCardView";
export const TripByCategory = () => {
  const [showSidebarToggler, setShowSidebarToggler] = useState(true); // Initial state is true

  const handleCustomSidebarButtonClick = () => {
    // Toggle the value of showSidebarToggler
    // Find the button inside the RevoCalendar using a query selector
    const button = document.querySelector(
      'button[aria-label="Toggle sidebar"]'
    );

    if (button) {
      // Click the button if it exists
      button.click();
    }
  };

  let { id, name, cal } = useParams();
  const decodeName = (formattedName) => {
    return formattedName
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  // Usage
  const originalName = decodeName(name);
  const calendarRef = React.useRef(null);

  // console.log("id", id);
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(Date.now());

  const [error, setError] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const convertDateToMilliseconds = (dateObject) => {
    const { day, month, year } = dateObject;
    const date = new Date(year, month + 1 - 1, day);
    return date.getTime();
  };
  const dateSelectedEvent = async (title) => {
    setLoading(true);
    // setMainData([]);
    // getData();
    let dateNew = convertDateToMilliseconds(title); // Assuming dateNew is in milliseconds
    let formattedDate = moment(dateNew).format("DD-MM-YYYY");
    const data = await getTripsByCategoryIdNew(id);
    if (data.data) {
      let xyz = findEventsByDate(data.data, formattedDate);
      // console.log("xyz", xyz);
      setSelectedDate(dateNew);
      if (xyz.length > 0) {
        console.log("setting maindata", xyz);
        setMainData(xyz);
      } else {
        console.log("clearning maindata");

        setMainData([]);
      }
    }
    setLoading(false);
  };
  function findEventsByDate(events, dateToFind) {
    const foundEvents = [];

    events.forEach((event) => {
      let flag = false;
      event.cities.forEach((city) => {
        if (Array.isArray(city.dates_new)) {
          city.dates_new.forEach((dateInfo) => {
            if (dateInfo.date === dateToFind) {
              flag = true;
            }
          });
        } else if (city.dates_new && city.dates_new.date === dateToFind) {
          flag = true;
        }
      });
      console.log("flag", flag);
      if (flag === true) {
        foundEvents.push(event);
      }
    });

    return foundEvents;
  }
  const getDates = (events) => {
    // console.log("getDates called");
    var newEvents = [];
    const currentDate = new Date(); // Get the current date and time

    events.forEach(function (event) {
      // console.log("foreach called");

      event.cities.forEach(function (city) {
        // console.log("cities called", city);

        if (Array.isArray(city.dates_new)) {
          // Handle the case where dates_new is an array of dates
          city.dates_new.forEach(function (dateInfo) {
            if (dateInfo.date) {
              // console.log("city date found", dateInfo);

              const formattedTimestamp = moment(
                dateInfo.date,
                "DD-MM-YYYY"
              ).valueOf(); // Convert to Unix timestamp in milliseconds

              if (formattedTimestamp > currentDate.getTime()) {
                newEvents.push({
                  title: event.title,
                  city: city.city_name,
                  date: formattedTimestamp,
                  allDay: event.allDay || false,
                });
              }
            } else {
              console.log("no dates found");
            }
          });
        } else if (city.dates_new && city.dates_new.date) {
          // Handle the case where dates_new is a single date
          // console.log("city date found", city.dates_new);

          const formattedDate = moment(
            city.dates_new.date,
            "DD-MM-YYYY"
          ).valueOf();

          newEvents.push({
            title: event.title,
            city: city.city_name,
            date: formattedDate,
            allDay: event.allDay || false,
          });
        } else {
          console.log("no dates found");
        }
      });
    });

    return newEvents;
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const data = await getTripsByCategoryIdNew(id);
        if (data.data) {
          console.log(data.data, "getTripsByCategoryId");
          setMainData(
            data.data.filter((data2, i) => {
              return data2?.isActive;
            })
          );
          let abc = getDates(data.data);
          if (abc) {
            setEventsData(abc);
            console.log("abc", abc);
          }
        } else {
          setError("Something Went Wrong !! ");
        }
      } catch (err) {
        console.log("err12123", err);
        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError(err.message);
        }
      }
      setLoading(false);
    };
    getData();
  }, [id]);
  const navigate = useNavigate();
  // const renderCustomSidebar = () => {
  //   return (
  //     <div>
  //       {/* Your custom button */}
  //       <button className="btn btn-primary">Chello ustom Button</button>

  //       {/* Other sidebar content */}
  //       <RevoCalendar.Sidebar />
  //     </div>
  //   );
  // };
  // const setErrorToast = (errorFromBackend) => {
  //   toast.error(
  //     <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
  //       Error: {error.length > 0 ? error : errorFromBackend}
  //     </p>,
  //     {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //       autoClose: 2000,
  //       theme: "colored",
  //     }
  //   );
  // };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "100.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        {/* <h2 className="tripsByCategoryText">{originalName}</h2> */}
      </div>
      <ToastContainer />
      <div className="phoneNumberDivLogin">
        <div className="row">
          {cal === "0" && (
            <>
              <div className="col-lg-8" style={{}}>
                <div className="calendar-container">
                  <button
                    className="custom-sidebar-button"
                    onClick={handleCustomSidebarButtonClick}
                  >
                    View Calender
                    <img src={animation} style={{ height: "40px" }} alt="" />
                  </button>
                </div>
                <RevoCalendar
                  ref={calendarRef}
                  events={eventsData}
                  style={{
                    borderRadius: "5px",
                    border: "5px solid #36b54a",
                    height: "100%",
                    maxHeight: "600px",
                    width: "100%",
                  }}
                  highlightToday={true}
                  lang="en"
                  primaryColor="#36b54a"
                  secondaryColor="#ffffff"
                  todayColor="#ffffff"
                  textColor="black"
                  indicatorColor="#36b54a"
                  animationSpeed={300}
                  sidebarWidth={180}
                  detailWidth={280}
                  detailDefault={false}
                  showDetailToggler={false}
                  showSidebarToggler={showSidebarToggler}
                  sidebarDefault={false}
                  onePanelAtATime={false}
                  selectedColor="red" // Change this line to set the highlight color
                  // allowDeleteEvent={true}
                  // allowAddEvent={true}
                  openDetailsOnDateSelection={false}
                  // timeFormat24={true}
                  showAllDayLabel={true}
                  detailDateFormat="DD/MM/YYYY"
                  // deleteEvent={deleteEvent}
                  // addEvent={addEvent}
                  dateSelected={dateSelectedEvent}
                ></RevoCalendar>
                {/* Add your custom button outside the calendar */}
              </div>
              <div
                className="col-lg-4"
                style={{
                  backgroundColor: "#fbfbfb",
                  borderRadius: "10px",
                }}
              >
                <div className="row">
                  <div className="col-lg-12">
                    <h3 style={{ marginTop: 10, marginBottom: 10 }}>
                      {" "}
                      {moment(selectedDate).format("MMM Do YY")}
                      {/* {moment(moment(selectedDate,'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss')).valueOf()} */}
                      {/* {moment(selectedDate,'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss')} */}
                      {/* {selectedDate} */}
                    </h3>
                  </div>
                </div>

                <div style={{ overflowY: "auto", maxHeight: "600px" }}>
                  {loading && (
                    <div className="col-lg-12 p-2 text-center">
                      <CircularProgress
                        size={60}
                        style={{ color: "#36b54a" }}
                      />
                    </div>
                  )}

                  {mainData &&
                    mainData.map((data, i) => {
                      return <TripCardView tripDetails={data} />;
                    })}
                  {mainData && mainData.length === 0 && (
                    <div className="alert alert-info">No Data Found</div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="col-lg-12 mt-5">
            <h1>{originalName}</h1>
          </div>
          {loading && (
            <div className="col-lg-12 p-2 text-center">
              <CircularProgress size={60} style={{ color: "#36b54a" }} />
            </div>
          )}
          {mainData &&
            mainData.map((data, i) => {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  className="col-lg-4 mt-5"
                  onClick={() => {
                    navigate(`/tours/${data?.slug}`);
                  }}
                >
                  <Card className=" eventsCards">
                    <CardMedia
                      sx={{
                        height: "180px",
                        width: "100%",
                        borderRadius: "11px",
                      }}
                      image={data?.images[0]?.link}
                      title={data?.title}
                    />
                    {data?.tripLabel !== "none" && (
                      <div className="tripLabel">
                        {data?.tripLabel === "groupTrip"
                          ? "Group Trip"
                          : data?.tripLabel === "customised"
                          ? "Customised"
                          : ""}
                      </div>
                    )}
                    <span className="tripbycategoryTitle">{data?.title}</span>
                    <br />
                    <span className="tripbycategorySubTitle mx-1">
                    {data?.tripLabel === "customised" ? "Available On Request" :<>From ₹ {data?.cities[0]?.prices[0]?.price}/-{" "}
                      <Iconfiy
                        style={{ marginRight: "5px", marginLeft: "auto" }}
                        icon="uil:calender"
                      />
                      {data?.cities[0]?.duration}</> }
                      
                    </span>
                  </Card>
                </div>
              );
            })}

          {!mainData && (
            <>
              <h1>No Result Found!</h1>
            </>
          )}
          {/* <div className="col-lg-4 eventsCards">
                test
            </div> */}
        </div>
      </div>
    </div>
  );
};
