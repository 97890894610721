import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getDynamicData } from "../api";

export const PrivacyPolicy = () => {
  const [error, setError] = useState("");
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);

  const setErrorToast = useCallback(
    (errorFromBackend) => {
      toast.error(
        <p className="text-white tx-16 mb-0">
          Error: {error.length > 0 ? error : errorFromBackend}
        </p>,
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 2000,
          theme: "colored",
        }
      );
    },
    [error]
  );

  const getData = useCallback(async () => {
    try {
      const apiData = await getDynamicData();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getAllCities", apiData);
        setMainData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        setErrorToast(err.response.data.message);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        setErrorToast(err.message);
      } else {
        setError("something went wrong");
        setErrorToast("something went wrong");
      }
    }
    setLoading(false);
  }, [setErrorToast]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div style={{ position: "relative", marginBottom: "50px" }}>
      <div
        style={{
          width: "100%",
          height: "150px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
          // paddingLeft:"12%",
          // paddingRight:"12%",
        }}
      >
        <h2 className="privacyPolicyScreenText">Privacy Policy</h2>
        {/* <h5 className="loginScreenTextBelow">
          Life is either a daring adventure or nothing.
        </h5> */}
      </div>
      <div className="phoneNumberDivLogin">
        At TrekPanda, we strongly believe in protecting consumer privacy. That's
        why we only ask you for information related to the services we provide.
        We never sell, rent, share, trade or give away any of your personal
        information to anyone. This privacy policy describes what information we
        collect from you, how it is used and what security measures we take to
        protect it.
        <br />
        <br />
        <br />
        IP Addresses & Cookies In order for us to provide an attractive and
        easy-to-navigate website, we must occasionally use our visitors’ IP
        addresses for purposes such as optimising our use of search engines or
        diagnosing problems with our server. Furthermore, to better tailor our
        website and product offerings to our customers, we use Google Analytics
        to collect information on how visitors navigate our website. Cookies are
        used to collect aggregated data that allows us to see the origins of web
        traffic, amount of visitors, visitors’ operating systems, browser types,
        etc. This data does not personally identify you in any way. You can
        choose not to accept cookies by disabling them in the settings of your
        web browser. <br />
        <br />
        <br /> Bookings & Payments When you book a tour on our website, we
        require contact information, such as your name and e-mail address and,
        in some cases, partial or full postal address or telephone number. This
        information is only used to contact you with information about your
        tour. In rare cases, we may need to share your contact information with
        tour guides who are responsible for the booked tour. Otherwise, we do
        not sell or share this information with any third parties. At the time
        of booking, we also ask you for payment information such as credit card
        number or bank transfer details. We use the utmost level of online
        security to safeguard your personal data. <br />
        <br />
        <br /> Online Security Our website is protected by the utmost level of
        online security, meaning your personal data is safe with us. All
        transactions on the site are secured by COMODO and any data we gather
        from users is securely stored and safeguarded by the best firewalls
        available.
        <br />
        <br />
        <br />
        All Rights Reserved No content on our website may be used or reproduced
        for any commercial purposes. TrekPanda reserves the right to amend this
        privacy policy at any time.
      </div>
    </div>
  );
};
