import React, { useState } from 'react'
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

const ImgWithLoader = ({ icon, hoveredCard }) => {
    const [loading, setLoading] = useState(true);
  return (
    <>
      {loading ? (
        <div
          className="mainSectionsCategories"
          style={{
            cursor: "pointer",
            height: "818px",
            padding: "10px"
          }}
        >
          <Stack direction="row" spacing={2}>
            <Stack spacing={1}>
              <Skeleton
                variant="rectangular"
                sx={{ marginTop: "5px" }}
                width={225}
                height={300}
              />
              <Skeleton
                variant="text"
                className="mainSectionsSubTitle"
                sx={{ fontSize: "1rem", width: "150px" }}
              />
            </Stack>
          </Stack>
        </div>
      ):('')}
      <img
        src={icon}
        alt="Card Background"
        style={{ borderRadius: "10px", display: loading ? "none" : "block" }}
        className={`card-image ${hoveredCard ? "hover" : ""}`}
        onLoad={() => setLoading(false)}
      />
    </>
  );
};

export default ImgWithLoader