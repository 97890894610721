import React, { useCallback, useState, useEffect } from "react";
// import Button from "@mui/material/Button";
import { Icon as Iconfiy } from "@iconify/react";
import { useNavigate } from "react-router-dom";
// import Card from "@mui/material/Card";
// import Drawer from "@mui/material/Drawer";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import TestimonialCard from "material-testimonial-card";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { getJobs, tripByCityName } from "../api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export const Careers = () => {
  const [selectedDate, setSelectedDate] = useState(Date.now());
  const [loading, setLoading] = useState(false);

  const [contactUsModal, setcontactUsModal] = useState(false);
  const showContactUsModal = () => setcontactUsModal(true);
  const hideContactUsModal = () => setcontactUsModal(false);

  const userInfo = useSelector((state) => state.userInformation);

  const [eventsData, setEventsData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [tripsData, setTripsData] = useState([]);

  const getData = useCallback(async () => {
    setTripsData();
    setEventsData();
    try {
      const apiData = await getJobs();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getAllCities", apiData);
        setMainData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
      // if (err.response) {
      //   setError(err.response.data.message);
      //   setErrorToast(err.response.data.message);
      // } else if (err.name === "AxiosError") {
      //   setError(err.message);
      //   setErrorToast(err.message);
      // } else {
      //   setError("something went wrong");
      //   setErrorToast("something went wrong");
      // }
    }
    // setLoading(false);
  }, []);
  const getDataByDatesOfMonthNew = (dataArray, milliseconds) => {
    const filteredData = dataArray.filter((item) => {
      return item.cities.some((city) => {
        return (
          city.testName2 === userInfo.selectedCity &&
          city.monthData.some((month) => {
            return month.datesOfMonthNew.includes(milliseconds);
          })
        );
      });
    });
    return filteredData;
  };
  const getTripsData = useCallback(async () => {
    // setEventsData();
    try {
      let body = {
        cityName: userInfo.selectedCity,
      };
      const apiData = await tripByCityName(body);
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getTripsData", apiData);
        const filteredData = getDataByDatesOfMonthNew(
          apiData.data,
          selectedDate
        );
        // console.log("filteredData", filteredData);

        setTripsData(filteredData);
        const getUniqueValues = (array) => {
          const uniqueValues = [...new Set(array)];
          return uniqueValues.map((value) => ({
            date: value,
            allDay: true,
          }));
        };

        let trips = apiData.data;
        // Extract unique datesOfMonthNew values
        const uniqueDatesOfMonthNew = getUniqueValues(
          trips.flatMap((trip) =>
            trip.cities.flatMap((city) =>
              city.monthData.flatMap((month) => month.datesOfMonthNew)
            )
          )
        );
        // setEventsData()
        // console.log("uniqueDatesOfMonthNew", uniqueDatesOfMonthNew);
        setEventsData(uniqueDatesOfMonthNew);
      }
    } catch (err) {
      console.log("err", err.name);
      // if (err.response) {
      //   setError(err.response.data.message);
      //   setErrorToast(err.response.data.message);
      // } else if (err.name === "AxiosError") {
      //   setError(err.message);
      //   setErrorToast(err.message);
      // } else {
      //   setError("something went wrong");
      //   setErrorToast("something went wrong");
      // }
    }
    // setLoading(false);
  }, [userInfo, selectedDate]);

  useEffect(() => {
    getData();
    //     getTripsData();
  }, [userInfo, selectedDate]);

  let id = "123";
  // const [mainData, setMainData] = useState([]);
  const [error, setError] = useState("");

  const convertDateToMilliseconds = (dateObject) => {
    const { day, month, year } = dateObject;
    const date = new Date(year, month + 1 - 1, day);
    return date.getTime();
  };
  const dateSelectedEvent = (title) => {
    // console.log("title", title);
    let dateNew = convertDateToMilliseconds(title);
    setSelectedDate(dateNew);
  };
  var events = [
    // {
    //   name: "Buyout",
    //   date: Date.now(),
    //   allDay: true,
    // },
    {
      name: "Reservation",
      date: Date.now(),
      extra: {
        icon: "M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09           4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z",
        text: "7 People",
      },
    },
  ];
  useEffect(() => {
    //     console.log("useEffect called");
    //     getData();
  }, []);
  const navigate = useNavigate();

  const setErrorToast = (errorFromBackend) => {
    toast.error(
      <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
        Error: {error.length > 0 ? error : errorFromBackend}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
        theme: "colored",
      }
    );
  };
  const formsSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    // formData.append("icon", selectedImage1);
    // console.log("formData", formData);
    const formJson = Object.fromEntries(formData.entries());
    // addData(formJson);
    setLoading(false);
  };
  // const addData = async (body) => {
  //   setError("");
  //   // setSuccessMessage("");

  //   setLoading(true);

  //   try {
  //     const apiData = await createEnquiry(body);
  //     if (apiData.error) {
  //       setError(apiData.error);
  //     } else {
  //       // getData();
  //       // handleClose3();
  //       alert("Response Submitted")
  //       // toast.success(
  //       //   <p className="text-white tx-16 mb-0">
  //       //     Success : Enquiry Submitted !
  //       //   </p>,
  //       //   {
  //       //     position: toast.POSITION.TOP_RIGHT,
  //       //     hideProgressBar: false,
  //       //     autoClose: 3000,
  //       //     theme: "colored",
  //       //   }
  //       // );
  //     }
  //   } catch (err) {
  //     console.log("err", err.name);
  //     if (err.response) {
  //       setError(err.response.data.message);
  //       // setErrorToast(err.response.data.message);
  //     } else if (err.name === "AxiosError") {
  //       setError(err.message);
  //       // setErrorToast(err.message);
  //     } else {
  //       setError("something went wrong");
  //       // setErrorToast("something went wrong");
  //     }
  //   }

  //   hideContactUsModal();
  //   window.scrollTo({ top: 0, left: 0 });
  //   // setLoading(false);
  // };
  return (
    <div style={{ position: "relative", marginBottom: "50px" }}>
      <div
        style={{
          width: "100%",
          height: "200.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
          // paddingLeft:"12%",
          // paddingRight:"12%",
        }}
      >
        <h2 className="loginScreenTextCareer">Careers</h2>
        <h5 className="loginScreenTextBelowCareer">
          Come join our team at Trek Panda to experience an exciting workplace
          culture and community that empowers you to grow.
        </h5>
      </div>
      <div className="phoneNumberDivLogin">
        <div className="row">
          {mainData &&
            mainData.map((data, i) => {
              return (
                <>
                  <div className="col-lg-4">
                    <div className="contactUsDiv" style={{ height: "300px" }}>
                      <span className="contactUsHeading">{data?.title}</span>
                      <br />
                      <br />
                      <span className="contactUsDetailAddress">
                        {data?.subTitle}
                      </span>
                      <br />
                      <br />
                      <Iconfiy icon="ri:time-line" />{" "}
                      <span style={{ marginLeft: "5px" }}>
                        {" "}
                        <i className="fa fa-map"></i> {data?.type}
                      </span>
                      <br />
                      <Iconfiy icon="carbon:map" />{" "}
                      <span style={{ marginLeft: "5px" }}>
                        {" "}
                        <i className="fa fa-map"></i> {data?.location}
                      </span>
                      <div className="text-right">
                        {/* <span className="contactUsViewMap">View On Map</span> */}
                        <Iconfiy
                          onClick={showContactUsModal}
                          color="#36b54a"
                          icon="iconoir:arrow-tr"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

          <Modal size="lg" show={contactUsModal} onHide={hideContactUsModal}>
            <ModalHeader>
              <ModalTitle>Submit Resume</ModalTitle>
              <span className="d-flex ms-auto" onClick={hideContactUsModal}>
                <i className="fe fe-x ms-auto"></i>
              </span>
            </ModalHeader>
            <form
              method="post"
              onSubmit={formsSubmit}
              // autocomplete="off"
              encType="multipart/form-data"
            >
              <ModalBody style={{ fontFamily: "Montserrat" }}>
                <div className="form-group">
                  <label htmlFor="">
                    Name <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    required
                    name="name"
                    placeholder="Enter First Name And Last Name"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">
                    Mobile <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="number"
                    required
                    name="mobile"
                    placeholder="Enter Mobile Number"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">
                    Email <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="email"
                    required
                    name="email"
                    placeholder="Enter Email"
                    className="form-control"
                  />
                  <input
                    type="hidden"
                    required
                    name="trip"
                    // value={title || ''}
                    placeholder="Enter Email"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">
                    Upload Resume <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="file"
                    required
                    name="resume"
                    // value={title || ''}
                    // placeholder="Enter Email"
                    className="form-control"
                  />
                </div>
              </ModalBody>

              <ModalFooter>
                <button className="btn btn-success" type="submit">
                  Submit
                </button>
                <button
                  className="btn btn-danger"
                  variant="secondary"
                  onClick={hideContactUsModal}
                >
                  Close
                </button>
              </ModalFooter>
            </form>
          </Modal>
        </div>
      </div>
    </div>
  );
};
