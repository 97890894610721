import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { allTripsNew } from "../api";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Col, Row } from "react-bootstrap";
import debounce from "lodash.debounce";

export const SearchAllTrips = () => {
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Debounced function for fetching trips based on the search query
  const fetchTrips = useCallback(
    debounce(async (query) => {
      setLoading(true);
      try {
        const { data } = await allTripsNew({ searchQuery: query });
        if (data) {
          setMainData(data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }, 500),
    []
  );

  // Handle search input change and trigger debounced fetch
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    fetchTrips(query);
  };

  useEffect(() => {
    // Initial load for all trips
    fetchTrips("");
  }, [fetchTrips]);

  // Skeleton card component for loading state
  const renderSkeletonCard = () => {
    return (
      <div
        className="col-lg-4 mt-5"
        style={{ cursor: "pointer", marginTop: "10px" }}
      >
        <Card className="eventsCards">
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: "180px", width: "100%", borderRadius: "11px" }}
          />
          <Stack spacing={1} sx={{ padding: "16px" }}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: "1rem", width: "150px" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: "0.85rem", width: "100px" }}
            />
          </Stack>
        </Card>
      </div>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "168.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        <h2 className="tripsByCategoryText">Search All Trips</h2>
      </div>

      <div className="phoneNumberDivLogin">
        <div className="row">
          <div className="col-lg-12 mt-5">
            <Row>
              <Col lg={8}>
                <input
                  type="text"
                  name="searchTrips"
                  id="searchTrips"
                  className="form-control form-control-lg"
                  placeholder="Search Trips"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </Col>
            </Row>
          </div>

          {loading
            ? // Render 6 skeleton cards while loading
              [...Array(6)].map((_, index) => <React.Fragment key={index}>{renderSkeletonCard()}</React.Fragment>)
            : mainData.map((data, i) => (
                <div
                  key={i}
                  style={{ cursor: "pointer" }}
                  className="col-lg-4 mt-5"
                  onClick={() => {
                    navigate(`/tours/${data?.slug}`);
                  }}
                >
                  <Card className="eventsCards">
                    <CardMedia
                      sx={{
                        height: "180px",
                        width: "100%",
                        borderRadius: "11px",
                      }}
                      image={data?.images[0]?.link}
                      title={data?.title}
                    />
                    {data?.tripLabel !== "none" && (
                      <div className="tripLabel">
                        {data?.tripLabel === "groupTrip"
                          ? "Group Trip"
                          : data?.tripLabel === "customised"
                          ? "Customised"
                          : null}
                      </div>
                    )}
                    <span
                      className="tripbycategoryTitle"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1, // Limits to 1 line
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.title}
                    </span>
                    <br />
                    <span className="tripbycategorySubTitle">
                      {data?.tripLabel === "customised" ? "Available On Request" :<>From ₹ {data?.cities[0]?.prices[0]?.price}/ -{" "}
                      {data?.cities[0]?.duration}</> }
                      
                    </span>
                  </Card>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};
