// reducer.js
import { UPDATE_USER_INFORMATION } from "./actions";

// Initial state
const initialState = {
  userInformation: {
    participant: [],
    total_fees: 0,
    transaction_charges: 0,
    discount: 0,
    SubtotalWithDiscount: 0,
    paymentId: 0,
    bookingDate: 0,
    bookingTime: 0,
    bookingType: 0,
    trip_name: 0,
    trip_id: 0,
    trip_tcs: 0,
    trip_date: 0,
    trip_city: 0,
    city_id: 0,
    trip_amount: 0,
    participant_count: 1,
    selectedCity: "Pune",
    partialPayment: 0,
    gstAmount: 0,
    tcsAmount: 0,
    addONAmount: 0,
    subTotalAmount: 0,
    showOfflineBooking: false,
    addOn: [],
    totalAmount:0,
    selectedAddOn: [],
    tripCategories: [],
    promocodeAmount: 0,
    promocodeUsed: "",
    customer_mobile: "",
    customer_id: "",
    paymentType: "",
    bookingStatus: "",
    priceTitle:"",
  },
};

// Reducer function
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_INFORMATION:
      return {
        ...state,
        userInformation: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
