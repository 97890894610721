import { Icon as Iconfiy } from "@iconify/react";
import Button from "@mui/material/Button";
import React, { useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { generateOTPCustomer, verifyOTPCustomer } from "../api";

export const CustomerLogin = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInformation);
  const [backendOTP, setBackendOTP] = useState("");
  const [otp, setOtp] = useState("");
  const [passwordshow, setpasswordshow] = useState(false);
  const [data, setData] = useState({
    mobile: "123123",
    password: "admin",
  });
  const [err, setError] = useState("");
  const [loading, setLoader] = useState(false);
  const [dealerData, setDealerData] = useState(false);
  const [mobile, setMobile] = useState("");
  const [id2, setId] = useState("");
  const inputRefs = useRef([]);
  const handleChange = (e, index) => {
    const value = e.target.value;
    setOtp((prevOTP) => {
      const newOTP =
        prevOTP.substring(0, index) + value + prevOTP.substring(index + 1);
      return newOTP;
    });

    if (value && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const checkOTP = async (e) => {
    // console.log("Login Function called");
    setLoader(true);
    if (otp.length > 4 || otp.length < 4) {
      setError("OTP should be 4 digits");
    } else {
      setError("");
      try {
        let body = {
          mobile: mobile,
          otp: otp,
        };
        const apiData = await verifyOTPCustomer(body);
        if (apiData.error) {
          setError(apiData.error);
        } else {
          // console.log("checkOTP data", apiData);
          // console.log("customerId", apiData.data.customerId);
          // localStorage.setItem("user_id", apiData.data.customerId);
          localStorage.setItem("customer_id", apiData.data.customerId);
          localStorage.setItem("user_token", apiData.data.token);
          localStorage.setItem(
            "isPhoneNumberVerified",
            apiData.data.isPhoneNumberVerified
          );
          localStorage.setItem("user_type", "customer");
          localStorage.setItem("user_mobile", mobile);
          RouteChange();
        }
      } catch (err) {
        if (err.response) {
          setError(err.response.data.message);
          setOtp("")
        } else {
          setError(err.message);
        }
      }
    }
    setLoader(false);
  };
  const RouteChange = (customerId) => {
    let path = `/customerPayNowScreen`;
    navigate(path);
  };

  const checkMobileNumber = async (e) => {
    console.log("checkMobileNumbercalled");
    setError();
    setLoader(true);
    if (mobile.length === 10) {
      // e.preventDefault();
      try {
        const apiData = await generateOTPCustomer(mobile);
        if (apiData.error) {
          setError(apiData.error);
        } else {
          console.log("checkMobileNumber", apiData);

          setBackendOTP(apiData.data.otp.otp);
          setId(apiData.data.customerId);
          localStorage.setItem("user_id", apiData.data.customerId);
        }
      } catch (err) {
        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError(err.message);
        }
      }
    } else {
      setError("Mobile number must contain 10 digits");
    }

    setLoader(false);
  };

  const checkMobileNumberForm = (e) => {
    e.preventDefault();
    checkMobileNumber();
  }

  const checkOtpForm = (e) => {
    e.preventDefault();
    checkOTP();
  }


  

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "168.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        <h2 className="registerScreenText">Register</h2>
      </div>
      <div className="phoneNumberDivLogin">
        {true && (
          <>
            {" "}
            {err && <Alert variant="danger">{err}</Alert>}
            {backendOTP ? (
              <form onSubmit={checkOtpForm}>
                <p className="phoneNumberText">
                  Please Enter 4 Digit OTP, Sent on your Mobile : {mobile}{" "}
                  {/* <span className="text-danger">{backendOTP}</span> */}
                </p>
                <div>
                  {Array.from({ length: 4 }, (_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      style={{
                        width: "45px",
                        height: "45px",
                        border: "1px solid gray",
                        borderRadius: "5px",
                        textAlign: "center",
                        margin: "5px",
                        fontSize: "14px",
                      }}
                      value={otp[index] || ""}
                      onChange={(e) => handleChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                    />
                  ))}
                </div>
                <div>
                  <Button
                    sx={{
                      marginTop: "16px",
                      width: "200.39px",
                      height: "50px",
                      background: "#36b54a",
                      border: "1px solid #36b54a",
                      color: "#FFFFFF",
                      // marginBottom:"5px",
                      padding: "7px 16.39px 7px 16px",
                      borderRadius: "9.6px",
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      // marginBottom:"10px"
                      // marginBottom:"-20px"
                    }}
                    className="sendOtpButton button_hover"
                    onClick={() => {
                      checkOTP();
                    }}
                    type="submit"
                  >
                    {"Verify"}
                    {/* {loading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      ""
                    )} */}
                    <Iconfiy
                      width="20"
                      height="28"
                      color="#fff"
                      icon="uil:arrow-right"
                    />{" "}
                  </Button>
                </div>
              </form>
            ) : (
              <>
                <form className="form-group" onSubmit={checkMobileNumberForm}>
                  <label htmlFor="" className="loginScreenTitle">
                    {" "}
                 Whatsapp Mobile Number
                  </label>
                  <input
                    style={{
                      paddingLeft: "10px",
                      //  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                      //  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                      //  box-sizing: border-box;         /* Opera/IE 8+ */
                    }}
                    type="number"
                    name="otp"
                    placeholder="Enter your whatsapp mobile number"
                    value={mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                      if (e.target.value.length > 10) {
                        setError("Mobile number must contain 10 digits");
                      } else {
                        setError("");
                      }
                    }}
                    className="phoneNumberInput"
                  />
                  <p className="phoneNumberText">
                  We'll send a One Time Password (OTP) to this phone number via WhatsApp as well as a text message to log in to your account.
                  </p>
                  <Button
                    sx={{
                      marginTop: "16px",
                      width: "136.39px",
                      height: "38px",
                      background: "#36b54a",
                      border: "1px solid #36b54a",
                      color: "#FFFFFF",
                      // marginBottom:"5px",
                      padding: "7px 16.39px 7px 16px",

                      borderRadius: "9.6px",
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      // marginBottom:"10px"
                      // marginBottom:"-20px"
                    }}
                    className="button_hover"
                    onClick={() => {
                      checkMobileNumber();
                    }}
                  >
                    {loading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      "Send OTP"
                    )}
                    <Iconfiy
                      width="20"
                      height="28"
                      color="#fff"
                      icon="uil:arrow-right"
                    />{" "}
                  </Button>
                </form>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
