// import { Slider } from "../component/Slider";
// import { Testimonial } from "../component/Testimonial";
// import { TreakSlider } from "../component/TreakSlider";
// import { TopVisited } from "../component/TopVisited";
// import { TrekByFilter } from "../component/TrekByFilter";
// import { Slider1 } from "../component/Slider1";
import { HomPageSlider } from "../component/HomPageSlider";
import { BackendEvents } from "../component/BackendEvents";
import ScrollToTop from "../component/ScrollToTop";
import WhatsappBtn from "../component/WhatsappBtn";

const Home = () => {
  // // window.location.reload();
  // const [value, Setvalue] = useState(0);
  // useEffect(() => {
  //   if (value === 0) {
  //     // window.location.reload();
  //   }
  //   Setvalue(1);
  // }, [value]);
  return (
    <>
    <ScrollToTop/>
      <WhatsappBtn/>
      <HomPageSlider />
      <BackendEvents />
    </>
  );
};

export default Home;
