import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Outlet, Link, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import LandscapeIcon from "@mui/icons-material/Landscape";
import DownloadIcon from "@mui/icons-material/Download";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CallIcon from "@mui/icons-material/Call";
import PersonIcon from "@mui/icons-material/Person";
import { Icon as Iconfiy } from "@iconify/react";

const SideBar = ({ toggleDrawer, state }) => {
  const customer_id = localStorage.getItem("customer_id");

  const LogoutFunction = () => {
    console.log("LogoutFunction called");
    const user_type = localStorage.getItem("user_type");
    localStorage.clear();
    navigate("/");
  };

  const navbarList = [
    {
      name: "Home",
      icon: <HomeIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "Events",
      icon: <LandscapeIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "Download",
      icon: <DownloadIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "About",
      icon: <BusinessIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "Team",
      icon: <PeopleAltIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: "Contact",
      icon: <CallIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: customer_id ? "Logout" : "Login",
      icon: <PersonIcon style={{ color: "#36b54a" }} />,
    },
    {
      name: customer_id ? "Profile" : "",
      icon: customer_id ? <PersonIcon style={{ color: "#36b54a" }} /> : null,
    },
  ];

  const SearchPageFunction = () => {
    navigate("/SearchAllTrips");
  };
  const AboutNavigation = () => {
    navigate("/About");
  };

  const navigate = useNavigate();

  const OnClickFunction = (name) => {
    console.log("OnClickFunctioncaleed", name);
    if (name === "Home") {
      navigate("/");
    } else if (name === "Logout") {
      LogoutFunction();
    } else if (name === "Events") {
      SearchPageFunction();
    } else if (name === "About") {
      AboutNavigation();
    } else if (name === "Team") {
      navigate("/Teams");
    } else if (name === "Login") {
      navigate("/CustomerLogin");
    } else if (name === "Contact") {
      navigate("/Contact");
    } else if (name === "Profile") {
      navigate("/user");
    } else {
      // alert("else");
    }
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: 300,
        height: "100%",
        zIndex: 3000000,
        fontFamily: "Montserrat",
        position: "relative",
        maxHeight: "900px",
        overflow: "auto",
        // textAlign: "left",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ zIndex: 3000000 }}>
        <ListItem
          key={"close"}
          sx={{ marginTop: 2, paddingLeft: "60%", zIndex: 3000000 }}
        >
          <ListItemButton></ListItemButton>
          <ListItemText
            onClick={toggleDrawer("right", false)}
            primary={<CloseIcon fontSize="1rem" />}
          />
        </ListItem>
        {navbarList &&
          navbarList.map((data, i) => {
            return (
              <ListItem
                onClick={() => {
                  OnClickFunction(data.name);
                }}
                key={data.name}
                sx={{ marginTop: "-10px", zIndex: 3000000 }}
              >
                <ListItemButton>
                  <ListItemIcon>{data.icon}</ListItemIcon>
                  <ListItemText
                    onClick={data.name === "login" ? LogoutFunction() : null}
                    sx={{
                      marginLeft: "-12px",
                      color: "#1c1c1c",
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      zIndex: 3000000,
                    }}
                    primary={data.name}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>

      <div className="bottomLeftCanvas">
        <Divider />
        <div style={{ padding: "10px" }}>
          <ListItemButton>
            <ListItemText
              sx={{ color: "#36b54a", fontWeight: 600 }}
              primary="Contact"
            />
          </ListItemButton>
          <ul style={{ marginLeft: "20px" }}>
            <li>
              <Iconfiy icon="material-symbols:call" />{" "}
              <span style={{ marginLeft: "5px" }}>+91 9284544815</span>
            </li>
            <li>
              <Iconfiy icon="material-symbols:call" />{" "}
              <span style={{ marginLeft: "5px" }}>+91 9284544815</span>
            </li>
            <li>
              <Iconfiy icon="material-symbols:alternate-email" />{" "}
              <span style={{ marginLeft: "5px" }}>trekpaanda@gmail.com</span>
            </li>
          </ul>
          <div className="scoialDIv">
            <a href="http://instagram.com/trek_panda" target="_blank" rel="noreferrer" className="socialLogo">
              <Iconfiy icon="mdi:instagram" width="24" height="24" />{" "}
            </a>
            <a href="http://facebook.com/trekpanda/" target="_blank" rel="noreferrer" className="socialLogo">
              <Iconfiy icon="mdi:facebook" width="24" height="24" />{" "}
            </a>
            <a href="http://youtube.com/channel/UCswW5joA4qDfgBv3lOgCTYA" target="_blank" rel="noreferrer" className="socialLogo">
              <Iconfiy icon="mdi:youtube" width="24" height="24" />{" "}
            </a>
            <a href="https://wa.me/918447937907" target="_blank" rel="noreferrer" className="socialLogo">
              <Iconfiy icon="mdi:whatsapp" width="24" height="24" />{" "}
            </a>
            <a href="https://maps.app.goo.gl/k4HveeQnf7CyBAAa6" target="_blank" rel="noreferrer" className="socialLogo">
              <Iconfiy icon="mdi:map-marker" width="24" height="24" />{" "}
            </a>
          </div>
        </div>
      </div>
    </Box>
  );
  return (
    <>
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        // variant="persistent"

        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
        transitionDuration={{ enter: 50, exit: 300 }}
      >
        {list("right")}
      </SwipeableDrawer>
    </>
  );
};

export default SideBar;
