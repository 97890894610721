import React from 'react'

const ContactUs = () => {
  return (
    <div>
        <div>
  {/* ================================
    START BREADCRUMB AREA
================================= */}
  <section className="breadcrumb-area bread-bg-5">
    <div className="breadcrumb-wrap">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="breadcrumb-content">
              <div className="section-heading">
                <h2 className="sec__title text-white">Contact us</h2>
              </div>
            </div>{/* end breadcrumb-content */}
          </div>{/* end col-lg-6 */}
          <div className="col-lg-6">
            <div className="breadcrumb-list text-right">
              <ul className="list-items">
                <li><a href="index.html">Home</a></li>
                <li>Pages</li>
                <li>Contact us</li>
              </ul>
            </div>{/* end breadcrumb-list */}
          </div>{/* end col-lg-6 */}
        </div>{/* end row */}
      </div>{/* end container */}
    </div>{/* end breadcrumb-wrap */}
    <div className="bread-svg-box">
      <svg className="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10" /></svg>
    </div>{/* end bread-svg */}
  </section>{/* end breadcrumb-area */}
  {/* ================================
    END BREADCRUMB AREA
================================= */}
  {/* ================================
    START CONTACT AREA
================================= */}
  <section className="contact-area section--padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <div className="form-box">
            <div className="form-title-wrap">
              <h3 className="title">We'd love to hear from you</h3>
              <p className="font-size-15">Send us a message and we'll respond as soon as possible</p>
            </div>{/* form-title-wrap */}
            <div className="form-content ">
              <div className="contact-form-action">
                <form method="post" className="row">
                  <div className="col-lg-6 responsive-column">
                    <div className="input-box">
                      <label className="label-text">Your Name</label>
                      <div className="form-group">
                        <span className="la la-user form-icon" />
                        <input className="form-control" type="text" name="text" placeholder="Your name" />
                      </div>
                    </div>
                  </div>{/* end col-lg-6 */}
                  <div className="col-lg-6 responsive-column">
                    <div className="input-box">
                      <label className="label-text">Your Email</label>
                      <div className="form-group">
                        <span className="la la-envelope-o form-icon" />
                        <input className="form-control" type="email" name="email" placeholder="Email address" />
                      </div>
                    </div>
                  </div>{/* end col-lg-6 */}
                  <div className="col-lg-12">
                    <div className="input-box">
                      <label className="label-text">Message</label>
                      <div className="form-group">
                        <span className="la la-pencil form-icon" />
                        <textarea className="message-control form-control" name="message" placeholder="Write message" defaultValue={""} />
                      </div>
                    </div>
                  </div>{/* end col-lg-12 */}
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="recapcha-box pb-4 d-flex align-items-center">
                        <label className="label-text flex-shrink-0 mr-3 mb-0">What is? 3 + 5 =</label>
                        <input className="form-control" type="text" name="text" placeholder="Type answer" />
                      </div>
                    </div>
                  </div>{/* end col-lg-12 */}
                  <div className="col-lg-12">
                    <div className="btn-box">
                      <button type="button" className="theme-btn">Send Message</button>
                    </div>
                  </div>{/* end col-lg-12 */}
                </form>
              </div>{/* end contact-form-action */}
            </div>{/* end form-content */}
          </div>{/* end form-box */}
        </div>{/* end col-lg-8 */}
        <div className="col-lg-4">
          <div className="form-box">
            <div className="form-title-wrap">
              <h3 className="title">Contact Us</h3>
            </div>{/* form-title-wrap */}
            <div className="form-content">
              <div className="address-book">
                <ul className="list-items contact-address">
                  <li>
                    <i className="la la-map-marker icon-element" />
                    <h5 className="title font-size-16 pb-1">Address</h5>
                    <p className="map__desc">
                    OFFICE NO 302 S.93/7B PALLAVI APT 3RD FLOOR, Prabhat Rd, Pune, Maharashtra 411004
                    </p>
                  </li>
                  <li>
                    <i className="la la-phone icon-element" />
                    <h5 className="title font-size-16 pb-1">Phone : </h5>
                    <p className="map__desc">Pune :
8447937907</p>
                    <p className="map__desc">Delhi/north :
7020216734</p>
                  </li>
                  <li>
                    <i className="la la-envelope-o icon-element" />
                    <h5 className="title font-size-16 pb-1">Email</h5>
                    <p className="map__desc">trekpaanda@gmail.com</p>
                  </li>
                </ul>
                <ul className="social-profile text-center">
                  <li><a href="#"><i className="lab la-facebook-f" /></a></li>
                  <li><a href="#"><i className="lab la-twitter" /></a></li>
                  <li><a href="#"><i className="lab la-instagram" /></a></li>
                  <li><a href="#"><i className="lab la-linkedin-in" /></a></li>
                  <li><a href="#"><i className="lab la-youtube" /></a></li>
                </ul>
              </div>
            </div>{/* end form-content */}
          </div>{/* end form-box */}
        </div>{/* end col-lg-4 */}
      </div>{/* end row */}
    </div>{/* end container */}
  </section>{/* end contact-area */}
  {/* ================================
    END CONTACT AREA
================================= */}
  {/* ================================
    START MAP AREA
================================= */}
  <section className="map-area padding-bottom-100px">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="map-container">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242087.85006382887!2d73.51639772143628!3d18.545946558953474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf6631d4502d%3A0x1eba1c4031afd062!2sTrek%20Panda!5e0!3m2!1sen!2sin!4v1681199519822!5m2!1sen!2sin" style={{width:"100%", height:"450px",border:"0px"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>{/* end map-container */}
        </div>{/* end col-lg-12 */}
      </div>{/* end row */}
    </div>{/* end container */}
  </section>{/* end map-area */}
</div>

    </div>
  )
}

export default ContactUs