import React from "react";
import section2 from "../assets/section2.jpeg";
import { useNavigate } from "react-router-dom";
import { Icon as Iconfiy } from "@iconify/react";

const TripCardView = ({ tripDetails }) => {
  let img = tripDetails?.images.find((img) => img.type === "Home Screen");
  const navigate = useNavigate();

  // console.log("test img", tripDetails?.title,img);
  return (
    <a
      onClick={() => {
        navigate(`/tours/${tripDetails?.slug}`);
      }}
      href="#"
      className="tripViewCard"
    >
      <img
        src={img?.link?.length > 0 ? img?.link : tripDetails?.images[0]?.link}
        style={{ width: 105.5, height: 100, borderRadius: 10 }}
        alt=""
      />
      <span className="bookingpricePerPersonCardView">
        {tripDetails?.title}
        {/* <br />₹ {tripDetails?.cities[0]?.prices[0]?.price} */}
        <br />
        <span className="tripbycategorySubTitle mt-3">
          From ₹ {tripDetails?.cities[0]?.prices[0]?.price}/-{" "}
          <Iconfiy
            style={{ marginRight: "5px", marginLeft: "25px" }}
            icon="uil:calender"
          />
          {tripDetails?.cities[0]?.duration}
        </span>
      </span>
    </a>
  );
};

export default TripCardView;
