import { useCallback, useEffect, useState } from "react";
import { getDynamicData } from "../api";
import { toast } from "react-toastify";

export const About = () => {
  const [error, setError] = useState("");
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);

  const setErrorToast = useCallback(
    (errorFromBackend) => {
      toast.error(
        <p className="text-white tx-16 mb-0">
          Error: {error.length > 0 ? error : errorFromBackend}
        </p>,
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 2000,
          theme: "colored",
        }
      );
    },
    [error]
  );

  const getData = useCallback(async () => {
    try {
      const apiData = await getDynamicData();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getAllCities", apiData);
        setMainData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        setErrorToast(err.response.data.message);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        setErrorToast(err.message);
      } else {
        setError("something went wrong");
        setErrorToast("something went wrong");
      }
    }
    setLoading(false);
  }, [setErrorToast]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div style={{ position: "relative", marginBottom: "50px" }}>
      <div
        style={{
          width: "100%",
          height: "200.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
          // paddingLeft:"12%",
          // paddingRight:"12%",
        }}
      >
        <h2 className="loginScreenTextAbout">About</h2>
        <h5 className="loginScreenTextBelow">Who we are & where do we stand</h5>
      </div>
      <div className="phoneNumberDivLogin">
        {loading ? (
          <h1 className="text-center m-5">Loading...</h1>
        ) : (
          <div
            className="timelineULOL"
            dangerouslySetInnerHTML={{
              __html: mainData && mainData[0]?.about_us,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};
