import React, { useCallback, useEffect, useState } from "react";
import {
  A11y,
  Autoplay,
  EffectFade,
  Keyboard,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TestimonialCard from "material-testimonial-card";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllBlogs,
  getAllCategories,
  getSections,
  getSliderImages,
  getTestimonials,
  getVideos,
} from "../api";
import ImgWithLoader from "./ImgWithLoader";
import SwiperComponent from "./SwiperComponent";
import { Box } from "@mui/material";

export const BackendEvents = () => {
  const userInfo = useSelector((state) => state.userInformation);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [mainSections, setMainSections] = useState([]);
  const [testimonialData, setTestimonialData] = useState([]);
  const [youtubeData, setYoutubeData] = useState([]);
  const [error, setError] = useState("");
  const [singleImage, setSingleImage] = useState([]);
  // const filterTripsByCity = (tripData, selectedCity) => {
  //   // console.log("filterTripsByCity called", tripData);
  //   return tripData.filter((item) => {
  //     if (item.trips && item.trips.length > 0) {
  //       // Check if any trip in the 'trips' array has the selected city
  //       return item.trips.some((trip) => {
  //         return (
  //           trip.tripId &&
  //           trip.tripId.cities &&
  //           trip.tripId.cities.some((city) => city.name === selectedCity)
  //         );
  //       });
  //     }
  //     return false;
  //   });
  // };
  const filterTripsByCityNew = (data, selectedCity) => {
    // console.log("datadata", data);
    if (data) {
      // return data.filter((trip) => {
      return (
        data.tripId &&
        data.tripId.cities &&
        data.tripId.cities.some((city) => city.name === selectedCity)
      );
      // });
    }
    return [];
  };
  function sortDataByType(data) {
    const sortedData = [...data];

    sortedData.sort((a, b) => {
      if (a.type === "image" && b.type !== "image") {
        return -1;
      } else if (a.type !== "image" && b.type === "image") {
        return 1;
      }
      return 0;
    });
    // console.log("setMainSections", sortedData);

    // Filter the trip data based on the selected city
    // const filteredTrips = filterTripsByCity(sortedData, userInfo?.selectedCity);
    // console.log("filteredTrips", filteredTrips);

    setMainSections(sortedData);
  }

  // function getTripsByCityId(tripsData, selectedCity) {
  //   // console.log("getTripsByCityId called", tripsData, selectedCity);
  //   const filteredTrips = [];

  //   tripsData &&
  //     tripsData.map((data, i) => {
  //       const tripsData = data.trips;
  //       tripsData &&
  //         tripsData.map((data2, i) => {
  //           const cities = data2.tripId.cities;
  //           // console.log("cities3123123123", cities);

  //           const filteredCities = cities.filter(
  //             (city) => city.name === selectedCity
  //           );
  //           if (filteredCities.length > 0) {
  //             const filteredTrip = { ...tripsData };
  //             // console.log("filteredTrip", filteredTrip);
  //             // filteredTrip.tripId.cities = filteredCities;
  //             // filteredTrips.push(filteredTrip);
  //           }
  //         });
  //     });

  //   return filteredTrips;
  // }

  // Usage example
  // const cityId = "6499974fc1257b79735a122e";
  // const filteredTrips = getTripsByCityId(response, cityId);
  // console.log(filteredTrips);

  const setErrorToast = useCallback(
    (errorFromBackend) => {
      toast.error(
        <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
          Error: {error.length > 0 ? error : errorFromBackend}
        </p>,
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 2000,
          theme: "colored",
        }
      );
    },
    [error]
  );
  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const apiData = await getAllCategories();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getAllCategories", apiData);
        setCategories(apiData.data);
      }
      const apiData2 = await getAllBlogs();
      if (apiData2.error) {
        setError(apiData2.error);
      } else {
        // console.log("getAllCategories", apiData2);
        setBlogs(apiData2.data);
      }
      const apiData3 = await getSliderImages();
      if (apiData3.error) {
        setError(apiData3.error);
      } else {
        // console.log("getSliderImages", apiData3);
        const singleimgage = apiData3?.data.find(
          (data) => data?.typeOfImage === "singleImage"
        );
        // console.log("singleimgage", singleimgage);
        setSingleImage(singleimgage);
      }
    } catch (err) {
      console.log("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        setErrorToast(err.response.data.message);
        navigate(`/errorPage/${err.response.data.message}`);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        setErrorToast(err.message);
        navigate(`/errorPage/${err.message}`);
      } else {
        setError("something went wrong");
        setErrorToast("something went wrong");
      }
    }
    try {
      const apiData = await getSections();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        sortDataByType(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        setErrorToast(err.response.data.message);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        setErrorToast(err.message);
      } else {
        setError("something went wrong");
        setErrorToast("something went wrong");
      }
    }
    try {
      const apiData = await getTestimonials();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getTestimonials", apiData);
        setTestimonialData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        setErrorToast(err.response.data.message);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        setErrorToast(err.message);
      } else {
        setError("something went wrong");
        setErrorToast("something went wrong");
      }
    }
    try {
      const apiData = await getVideos();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // console.log("getVideos", apiData);
        setYoutubeData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        setErrorToast(err.response.data.message);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        setErrorToast(err.message);
      } else {
        setError("something went wrong");
        setErrorToast("something went wrong");
      }
    }
    setLoading(false);
  }, [navigate, setErrorToast]);
  const [hoveredCard, setHoveredCard] = useState(false);

  function checkCityId(obj) {
    // console.log("cities123", obj);
    if (obj?.tripId) {
      for (const city of obj.tripId.cities) {
        if (city?.name === userInfo?.selectedCity) {
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    getData();
  }, [userInfo, getData]);

  return (
    <>
      <ToastContainer />
      <div
        className="mainSectionsCategories"
        style={{
          cursor: "pointer",
        }}
        // onClick={() => {
        //   navigate(sectionListData.url);
        // }}
      >
        <span className="mainSectionsTitle">Curated Categories</span>
        <span className="mainSectionsSubTitle">Curated Categories</span>
        <div className="row">
          {categories &&
            categories.map((data, i) => {
              if (!data.isActive || !data.showHomeScreen) {
                return null;
              }
              return (
                <div
                  key={i}
                  onMouseEnter={() => setHoveredCard(true)}
                  onMouseLeave={() => setHoveredCard(false)}
                  className={`col-lg-3 col-md-4 col-6  card-container`}
                  onClick={() => {
                    const formatName = (name) => {
                      return name
                        .split(" ")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join("-");
                    };

                    const formattedName = formatName(data?.name);

                    navigate(
                      `/category/${data._id}/${formattedName}/${
                        data?.hideCalender === true ? 1 : 0
                      }`
                    );
                  }}
                >
                  <div
                    // style={{width:"300px",height:"300px"}}
                    className={`background-image ${hoveredCard ? "hover" : ""}`}
                  />
                  <ImgWithLoader icon={data.icon} hoveredCard={hoveredCard} />

                  {/* <img
                      src={data.icon}
                      alt="Card Background"
                      style={{ borderRadius: "10px" }}
                      className={`card-image ${hoveredCard ? "hover" : ""}`}
                    /> */}
                  {/* <span className="subSectionTitle">{data.name}</span> */}
                </div>
              );
            })}
        </div>
        {/* <Swiper
          modules={[
            Navigation,
            Pagination,
            // Scrollbar,
            A11y,
            EffectFade,
            Autoplay,
            Keyboard,
          ]}
          Pagination={{
            clickable: true,
          }}
          // keyboard={true}
          // Mousewheel={true}
          breakpoints={{
            320: {
              slidesPerView: 1.3,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 2.3,
              spaceBetween: 50,
            },
            640: {
              slidesPerView: 3.3,
              spaceBetween: 40,
            },
            720: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
          }}
          style={{
            height: "500px",
            marginTop: "20px",
            width: "100%",
            textAlign: "center",
          }}
        >
          {categories &&
            categories.map((data, i) => {
              if (!data.isActive || !data.showHomeScreen) {
                return null;
              }

              return (
                <>
                  <SwiperSlide
                    onClick={() => {
                      navigate(`/TripByCategory/${data._id}`);
                    }}
                  >
                    <Card
                      className="mainSectionCard  card-container"
                      sx={{ borderRadius: "10px" }}
                      onMouseEnter={() => setHoveredCard(true)}
                      onMouseLeave={() => setHoveredCard(false)}
                      // className={`col-lg-3 col-md-3 col-6`}
                    >
                      <div
                  className={`background-image ${hoveredCard ? "hover" : ""}`}
                />
                <img
                  src={data.icon}
                  alt="Card Background"
                  style={{ borderRadius: "10px" }}
                  className={`card-image ${hoveredCard ? "hover" : ""}`}
                />
                      <CardMedia
                        sx={{ height: "100%", borderRadius: "10px" }}
                        image={data.icon}
                        title="green iguana"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                        ></Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        ></Typography>
                      </CardContent>

                    </Card>
                    <span className="subSectionTitle">{data.name}</span>
                  </SwiperSlide>
                </>
              );
            })}
        </Swiper> */}
      </div>

      <section id="backendSectionsData">
        {loading ? (
          <div
            className="mainSectionsCategories"
            style={{
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Stack spacing={1}>
                {/* For variant="text", adjust the height via font-size */}
                <Skeleton
                  variant="text"
                  className="mainSectionsTitle"
                  sx={{ fontSize: "1rem", width: "100px" }}
                />

                <Skeleton
                  variant="rectangular"
                  sx={{ marginTop: "5px" }}
                  width={200}
                  height={200}
                />
              </Stack>
            </Stack>
          </div>
        ) : (
          mainSections &&
          mainSections.map((mainSectionsData, mainSectionsi) => {
            if (!mainSectionsData?.isActive) {
              return null;
            }
            if (mainSectionsData.type === "image") {
              return (
                <div
                  key={mainSectionsData.title}
                  className="mainSections"
                  style={{
                    cursor: "pointer",
                  }}
                  // onClick={() => {
                  //   navigate(sectionListData.url);
                  // }}
                >
                  <SwiperComponent
                    title={mainSectionsData.title}
                    subTitle={mainSectionsData.title}
                    classTitle="mainSectionsTitle"
                    style={{ height: "280px !important" }}
                    breakpoints={{
                      320: {
                        slidesPerView: 1.7,
                        spaceBetween: 30,
                      },
                      480: {
                        slidesPerView: 2.3,
                        spaceBetween: 50,
                      },
                      640: {
                        slidesPerView: 2.5,
                        spaceBetween: 40,
                      },
                      768: {
                        slidesPerView: 2.8,
                        spaceBetween: 35,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 35,
                      },
                      1440: {
                        slidesPerView: 4.5,
                        spaceBetween: 40,
                      },
                    }}
                  >
                    {mainSectionsData.trips &&
                      mainSectionsData.trips.map((tripsData, i) => {
                        const checkDisplay = checkCityId(tripsData);
                        const imageLink = tripsData?.tripId?.images?.find(
                          (data222, i) => {
                            // console.log("find", data222);
                            return data222.type === "Home Screen With Image";
                          }
                        );
                        // console.log("imageLink", imageLink);
                        if (checkDisplay && imageLink?.length > 0) {
                          return (
                            <SwiperSlide
                              key={i}
                              onClick={() => {
                                navigate(`/tours/${tripsData.tripId?.slug}`);
                              }}
                            >
                              <Card
                                className="mainSectionCard"
                                sx={{ borderRadius: "10px" }}
                              >
                                <CardMedia
                                  sx={{
                                    height: "100%",
                                    borderRadius: "10px",
                                    boxShadow: " 0 0.5rem 1rem rgba(0,0,0,.35)",
                                  }}
                                  image={imageLink?.link}
                                  loading="lazy"
                                />
                                <div
                                  style={{
                                    position: "relative",
                                    bottom: "10%",
                                    height: "10px",
                                  }}
                                >
                                  {tripsData?.tripId.tripLabel !== "none" && (
                                    <div className="trip_label_homepage">
                                      {tripsData?.tripId.tripLabel ===
                                      "groupTrip"
                                        ? "Group Trip"
                                        : tripsData?.tripId.tripLabel ===
                                          "customised"
                                        ? "Customised"
                                        : null}
                                    </div>
                                  )}
                                </div>
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                  ></Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  ></Typography>
                                </CardContent>

                                {/* <img src={data.icon} style={{maxWidth:"100%",height:"auto",borderRadius:"9px"}} alt="" /> */}
                              </Card>
                              {/* <span className="subSectionTitle">
                            {data.icon}
                          </span> */}
                            </SwiperSlide>
                          );
                        }
                        return null;
                      })}
                  </SwiperComponent>
                </div>
              );
            }
            return (
              <div
                key={mainSectionsData.title}
                className={`${
                  mainSectionsi % 2 === 0
                    ? "mainSectionsOtherBgGray"
                    : "mainSectionsOther"
                }`}
              >
                {/* <span className="mainSectionsTitle">
                  {mainSectionsData.title}
                </span>
                <span className="iconContainer">
                  <Iconfiy
                    width="32"
                    height="32"
                    marginBottom="10"
                    color="#36b54a"
                    icon="mingcute:arrow-right-fill"
                  />
                </span>

                <Swiper
                  modules={[
                    Navigation,
                    Pagination,
                    A11y,
                    EffectFade,
                    Autoplay,
                  ]}
                  breakpoints={{
                    320: {
                      slidesPerView: 1.7,
                      spaceBetween: 10,
                    },
                    480: {
                      slidesPerView: 2.3,
                      spaceBetween: 50,
                    },
                    640: {
                      slidesPerView: 3.3,
                      spaceBetween: 40,
                    },
                    720: {
                      slidesPerView: 5,
                      spaceBetween: 40,
                    },
                  }}
                  // style={{
                  //   height: "460px",
                  //   marginTop: "20px",
                  //   width: "100%",
                  //   textAlign: "center",
                  // }}
                  className="swiperMain"
                >
                  {mainSectionsData.trips &&
                    mainSectionsData.trips.map((sectionListDataTrips, i) => {
                      console.log(
                        "sectionListDataTrips",
                        sectionListDataTrips
                      );
                      const xyz = filterTripsByCityNew(
                        sectionListDataTrips,
                        userInfo?.selectedCity
                      );
                      if (xyz)
                        return (
                          <>
                            <SwiperSlide
                              key={sectionListDataTrips?.tripId?.title}
                              onClick={() => {
                                navigate(
                                  `/tours/${sectionListDataTrips.tripId._id}`
                                );
                              }}
                            >
                              <CardMedia
                                sx={{
                                  height: "200px",
                                  width: "200px",
                                  borderRadius: "10px",
                                }}
                                image={
                                  sectionListDataTrips?.tripId?.images[0]
                                    ?.link
                                }
                                title={sectionListDataTrips?.tripId?.title}
                              />
                              <span className="subSectionTitle">
                                {sectionListDataTrips?.tripId?.title}
                              </span>
                            </SwiperSlide>
                          </>
                        );
                    })}
                </Swiper> */}
                <SwiperComponent
                  title={mainSectionsData.title}
                  style={{ height: "280px !important" }}
                  subTitle={""}
                  classTitle="mainSectionsTitle"
                  breakpoints={{
                    320: {
                      slidesPerView: 1.5,
                      spaceBetween: 30,
                    },
                    480: {
                      slidesPerView: 2.3,
                      spaceBetween: 50,
                    },
                    640: {
                      slidesPerView: 2.5,
                      spaceBetween: 40,
                    },
                    768: {
                      slidesPerView: 2.8,
                      spaceBetween: 35,
                    },
                    900: {
                      slidesPerView: 3.5,
                      spaceBetween: 35,
                    },
                    1024: {
                      slidesPerView: 3.8,
                      spaceBetween: 35,
                    },
                    1220: {
                      slidesPerView: 4.2,
                      spaceBetween: 35,
                    },
                    1440: {
                      slidesPerView: 4.8,
                      spaceBetween: 40,
                    },
                  }}
                >
                  {mainSectionsData.trips &&
                    mainSectionsData.trips.map((sectionListDataTrips, i) => {
                      // console.log("sectionListDataTrips", sectionListDataTrips);
                      // const xyz = filterTripsByCityNew(
                      //   sectionListDataTrips,
                      //   userInfo?.selectedCity
                      // );
                      if (true) {
                        return (
                          <SwiperSlide
                            key={sectionListDataTrips?.tripId?.title}
                            onClick={() => {
                              navigate(
                                `/tours/${sectionListDataTrips.tripId.slug}`
                              );
                            }}
                          >
                            <CardMedia
                              className="homepageSliderImages"
                              image={
                                sectionListDataTrips?.tripId?.images[0]?.link
                              }
                              title={sectionListDataTrips?.tripId?.title}
                            />
                            <div
                              style={{
                                position: "relative",
                                bottom: "10%",
                                height: "10px",
                              }}
                            >
                              {sectionListDataTrips?.tripId?.tripLabel !==
                                "none" && (
                                <div className="trip_label_homepage">
                                  {sectionListDataTrips?.tripId?.tripLabel ===
                                  "groupTrip"
                                    ? "Group Trip"
                                    : "Customised"}
                                </div>
                              )}
                            </div>
                            <span className="subSectionTitle">
                              {sectionListDataTrips?.tripId?.title}
                            </span>
                          </SwiperSlide>
                        );
                      }
                      return null;
                    })}
                </SwiperComponent>
              </div>
            );
          })
        )}
      </section>

      {/* {sectionsList &&
        sectionsList.map((sectionListData, i) => {
          return (
            <div
              className="mainSections"
              style={{
                cursor: "pointer",
              }}
              // onClick={() => {
              //   navigate(sectionListData.url);
              // }}
            >
              <span className="mainSectionsTitle">{sectionListData.title}</span>
              <span className="mainSectionsSubTitle">
                {sectionListData.subTitle}
              </span>
              <Swiper
                modules={[
                  Navigation,
                  Pagination,
                  // Scrollbar,
                  A11y,
                  EffectFade,
                  Autoplay,
                  Keyboard,
                ]}
                Pagination={{
                  clickable: true,
                }}
                // keyboard={true}
                // Mousewheel={true}
                breakpoints={{
                  320: {
                    slidesPerView: 1.3,
                    spaceBetween: 20,
                  },
                  480: {
                    slidesPerView: 2.3,
                    spaceBetween: 50,
                  },
                  640: {
                    slidesPerView: 3.3,
                    spaceBetween: 40,
                  },
                  720: {
                    slidesPerView: 5,
                    spaceBetween: 40,
                  },
                }}
                style={{
                  height: "360px",
                  marginTop: "20px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {sectionListData.trips &&
                  sectionListData.trips.map((data, i) => {
                    return (
                      <>
                        <SwiperSlide
                          onClick={() => {
                            navigate(data.url);
                          }}
                        >
                          <Card
                            className="mainSectionCard"
                            sx={{ borderRadius: "10px" }}
                          >
                            <CardMedia
                              sx={{ height: "100%", borderRadius: "10px" }}
                              image={data.icon}
                              title="green iguana"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              ></Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              ></Typography>
                            </CardContent>

                          </Card>

                        </SwiperSlide>
                      </>
                    );
                  })}
              </Swiper>
            </div>
          );
        })} */}

      {/* {sectionsList2 &&
        sectionsList2.map((sectionListData, i) => {
          return (
            <div
              className={`${
                i % 2 === 0 ? "mainSectionsOtherBgGray" : "mainSectionsOther"
              }`}
            >
              <span className="mainSectionsTitle">{sectionListData.title}</span>

              <Swiper
                modules={[
                  Navigation,
                  Pagination,
                  // Scrollbar,
                  A11y,
                  EffectFade,
                  Autoplay,
                  Keyboard,
                ]}
                // keyboard={true}
                breakpoints={{
                  320: {
                    slidesPerView: 1.3,
                    spaceBetween: 20,
                  },
                  480: {
                    slidesPerView: 2.3,
                    spaceBetween: 50,
                  },
                  640: {
                    slidesPerView: 3.3,
                    spaceBetween: 40,
                  },
                  720: {
                    slidesPerView: 5,
                    spaceBetween: 40,
                  },
                }}
                style={{
                  height: "460px",
                  marginTop: "20px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {sectionListData.trips &&
                  sectionListData.trips.map((sectionListDataTrips, i) => {
                    return (
                        <SwiperSlide>
                          <CardMedia
                            sx={{
                              height: "200px",
                              width: "200px",
                              borderRadius: "10px",
                            }}
                            image={sectionListDataTrips.icon}
                            title="green iguana"
                          />
                          <span className="subSectionTitle">
                            {sectionListDataTrips.tripTitle}
                          </span>

                        </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          );
        })} */}
      {loading ? (
        <div
          className="mainSectionsCategories"
          style={{
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}

            <Skeleton
              variant="rectangular"
              sx={{ marginTop: "5px" }}
              width={1200}
              height={320}
            />
          </Stack>
        </div>
      ) : (
        singleImage &&
        singleImage?.icon !== "" && (
          <div className="container mt-4 mb-4">
            <div className="hoverCard row mb-2 text-center">
              <a target="_blank" href={singleImage?.url} rel="noreferrer">
                {singleImage?.icon && (
                  <img
                    className="hoverImg"
                    src={singleImage?.icon}
                    alt="banner"
                    loading="lazy"
                  />
                )}
              </a>
            </div>
          </div>
        )
      )}

      {loading ? (
        <div
          className="mainSectionsBgGrayExplore"
          style={{
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}

            <Skeleton
              variant="rectangular"
              sx={{ marginTop: "5px" }}
              width={400}
              height={300}
            />
          </Stack>
        </div>
      ) : (
        <>
          <div className="mainSectionsBgGrayExplore">
            {/* <span className="mainSectionsTitle">Experience yourself</span>
            <span className="mainSectionsSubTitle">
              Exclusive footage from our camps
            </span> */}
            <SwiperComponent
              title={"Experience yourself"}
              style={{ height: "280px !important" }}
              subTitle={""}
              classTitle="mainSectionsTitle"
              breakpoints={{
                320: {
                  slidesPerView: 1.1,
                  spaceBetween: 30,
                },
                480: {
                  slidesPerView: 1.5,
                  spaceBetween: 50,
                },
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 40,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 35,
                },
                900: {
                  slidesPerView: 2.1,
                  spaceBetween: 35,
                },
                1024: {
                  slidesPerView: 2.5,
                  spaceBetween: 35,
                },
                1220: {
                  slidesPerView: 3,
                  spaceBetween: 35,
                },
                1440: {
                  slidesPerView: 3.5,
                  spaceBetween: 40,
                },
              }}
            >
              {youtubeData &&
                youtubeData.map((data, i) => {
                  return (
                    // <SwiperSlide key={i}>

                    // </SwiperSlide>
                    <SwiperSlide
                      key={data._id}
                      onClick={() => {
                        // navigate(`/tours/${sectionListDataTrips.tripId.slug}`);
                      }}
                    >
                      <Card
                        className="mainSectionCardExlpore"
                        sx={{ borderRadius: "10px" }}
                      >
                        <p dangerouslySetInnerHTML={{ __html: data.link }}></p>
                        {/* {data.link} */}
                      </Card>
                    </SwiperSlide>
                  );
                })}
            </SwiperComponent>
          </div>

          {/* Reviews slider */}
          <div className="mainSectionsCategories">
            <span className="mainSectionsTitle">Why People ❤️ TrekPanda</span>
            <span className="mainSectionsSubTitle">
              Experience the best with us
            </span>
            <span className="iconContainer"></span>
            <SwiperComponent
              // title={"Experience yourself"}
              // style={{ height: "280px !important" }}
              style={{
                height: "300px",
                marginTop: "20px",
                width: "100%",
                textAlign: "center",
              }}
              subTitle={""}
              classTitle="mainSectionsTitle"
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                  spaceBetween: 30,
                },
                480: {
                  slidesPerView: 1.2,
                  spaceBetween: 100,
                },
                640: {
                  slidesPerView: 1.4,
                  spaceBetween: 40,
                },
                768: {
                  slidesPerView: 1.8,
                  spaceBetween: 35,
                },
                1024: {
                  slidesPerView: 2.3,
                  spaceBetween: 35,
                },
                1220: {
                  slidesPerView: 2.5,
                  spaceBetween: 35,
                },
                1440: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
            >
              {testimonialData &&
                testimonialData.map((data, i) => {
                  // const regex = /(<([^>]+)>)/gi;
                  // const result = data.description.replace(regex, "");
                  if (!data?.isActive) {
                    return null;
                  }
                  return (
                    <SwiperSlide key={data.name}>
                      <Box
                        component="img"
                        src={data?.icon} // Replace with your image URL
                        alt="Example"
                        sx={{
                          width: "100%", // Set width for the image
                          height: "100%", // Set height for the image
                          borderRadius: "16px", // Set border radius for rounded corners
                          objectFit: "cover", // Ensures the image covers the container
                        }}
                      />
                    </SwiperSlide>
                  );
                })}
            </SwiperComponent>
          </div>
          {blogs && blogs?.length > 1 && (
            <>
              <div className="mainSections">
                <span className="mainSectionsTitle">Blogs</span>
                {/* <span className="mainSectionsSubTitle">
          Experience the best with us
        </span> */}
                {/* <Swiper
              modules={[
                Navigation,
                Pagination,
                // Scrollbar,
                A11y,
                EffectFade,
                Autoplay,
              ]}
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                  spaceBetween: 30,
                },
                480: {
                  slidesPerView: 1.5,
                  spaceBetween: 50,
                },
                640: {
                  slidesPerView: 2.5,
                  spaceBetween: 40,
                },
                768: {
                  slidesPerView: 2.8,
                  spaceBetween: 35,
                },
                1024: {
                  slidesPerView: 3.3,
                  spaceBetween: 35,
                },
                1220: {
                  slidesPerView: 3.5,
                  spaceBetween: 35,
                },
                1440: {
                  slidesPerView: 4.5,
                  spaceBetween: 40,
                },
              }}
              style={{
                height: "374px",
                marginTop: "20px",
                width: "100%",
                textAlign: "center",
              }}
            > */}
                <SwiperComponent
                  // style={{ height: "280px !important" }}

                  subTitle={""}
                  classTitle="mainSectionsTitle"
                  breakpoints={{
                    320: {
                      slidesPerView: 1.2,
                      spaceBetween: 30,
                    },
                    480: {
                      slidesPerView: 1.5,
                      spaceBetween: 50,
                    },
                    640: {
                      slidesPerView: 2.5,
                      spaceBetween: 40,
                    },
                    768: {
                      slidesPerView: 2.8,
                      spaceBetween: 35,
                    },
                    1024: {
                      slidesPerView: 3.3,
                      spaceBetween: 35,
                    },
                    1220: {
                      slidesPerView: 3.5,
                      spaceBetween: 35,
                    },
                    1440: {
                      slidesPerView: 4.5,
                      spaceBetween: 40,
                    },
                  }}
                  style={{
                    height: "374px",
                    marginTop: "20px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {blogs &&
                    blogs.map((data, i) => {
                      // const regex = /(<([^>]+)>)/gi;
                      // const result = data.description.replace(regex, "");
                      const formatName = (name) => {
                        return name
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join("-");
                      };

                      const formattedName2 = formatName(data?.title);
                      return (
                        <SwiperSlide key={data.title}>
                          <div className="card-item blog-card blog_card">
                            <div className="card-img">
                              <img
                                src={data.image}
                                style={{ width: "100%", height: "247px" }}
                                loading="lazy"
                                alt={data.title}
                              />
                              <div className="post-format icon-element">
                                <i className="la la-photo" />
                              </div>
                              <div className="card-body">
                                <h3 className="card-title line-height-26">
                                  <Link
                                    to={`/blogs_details/${data._id}/${formattedName2}`}
                                  >
                                    {data.title}
                                  </Link>
                                </h3>
                                <p className="card-meta">
                                  <span className="post__date">
                                    {data.date}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="card-footer d-flex align-items-center justify-content-between">
                              <div className="author-content d-flex align-items-center">
                                <div className="author-img">
                                  <img
                                    src="images/small-team1.jpg"
                                    loading="lazy"
                                    alt={data.title}
                                  />
                                </div>
                                <div className="author-bio">
                                  <Link
                                    to={`/blogs_details/${data._id}/${formattedName2}`}
                                  >
                                    {data?.author}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </SwiperComponent>
              </div>
            </>
          )}
        </>
      )}

      {/* {StoriesList &&
        StoriesList.map((sectionListData, i) => {
          return (
            <div className="mainSectionsBgGrayExploreStories">
              <span className="mainSectionsTitle">{sectionListData.title}</span>
              <span className="mainSectionsSubTitle">
                {sectionListData.subTitle}
              </span>
              <Swiper
                modules={[
                  Navigation,
                  Pagination,
                  // Scrollbar,
                  A11y,
                  EffectFade,
                  Autoplay,
                ]}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  480: {
                    slidesPerView: 2.3,
                    spaceBetween: 50,
                  },
                  640: {
                    slidesPerView: 3.3,
                    spaceBetween: 40,
                  },
                  720: {
                    slidesPerView: 3.5,
                    spaceBetween: 40,
                  },
                }}
                style={{
                  height: "320px",
                  marginTop: "20px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {sectionListData.trips &&
                  sectionListData.trips.map((data, i) => {
                    return (
                      <>
                        <SwiperSlide>
                          <Card
                            className="mainSectionCardExlpore"
                            sx={{ borderRadius: "10px" }}
                          >
                            {data.link}
                          </Card>
                          <span className="subSectionTitle">{data.title}</span>
                        </SwiperSlide>
                      </>
                    );
                  })}
              </Swiper>
            </div>
          );
        })} */}

      {/* <Testimonial /> */}
    </>
  );
};
