import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import { Icon as Iconfiy } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form, Alert, InputGroup } from "react-bootstrap";
import { generateOTPCustomer, verifyOTPCustomer } from "../api";

export const OrderCancelled = () => {
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.userInformation);
  console.log("userInfo", userInfo);
  const [backendOTP, setBackendOTP] = useState("");
  const [otp, setOtp] = useState("");
  const [passwordshow, setpasswordshow] = useState(false);
  const [data, setData] = useState({
    mobile: "123123",
    password: "admin",
  });
  const [err, setError] = useState("");
  const [loading, setLoader] = useState(false);
  const [dealerData, setDealerData] = useState(false);
  const [mobile, setMobile] = useState("");
  const [id2, setId] = useState("");
  const inputRefs = useRef([]);
  const handleChange = (e, index) => {
    const value = e.target.value;
    setOtp((prevOTP) => {
      const newOTP =
        prevOTP.substring(0, index) + value + prevOTP.substring(index + 1);
      return newOTP;
    });

    if (value && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const checkOTP = async (e) => {
    console.log("Login Function called");
    setLoader(true);
    if (otp.length > 4 || otp.length < 4) {
      setError("OTP should be 4 digits");
    } else {
      setError("");
      try {
        let body = {
          mobile: mobile,
          otp: otp,
        };
        const apiData = await verifyOTPCustomer(body);
        if (apiData.error) {
          setError(apiData.error);
        } else {
          console.log("checkOTP data", apiData);
          // localStorage.setItem("user_id", apiData.data.customerId);
          localStorage.setItem("customer_id", dealerData.customerId);
          localStorage.setItem("user_token", apiData.data.token);
          localStorage.setItem(
            "isPhoneNumberVerified",
            apiData.data.isPhoneNumberVerified
          );
          localStorage.setItem("user_type", "customer");
          localStorage.setItem("user_mobile", mobile);
          RouteChange();
        }
      } catch (err) {
        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError(err.message);
        }
      }
    }
    setLoader(false);
  };
  const RouteChange = (customerId) => {
    let path = `/customerPayNowScreen`;
    navigate(path);
  };

  const checkMobileNumber = async (e) => {
    console.log("checkMobileNumbercalled");
    setError();
    setLoader(true);
    if (mobile.length === 10) {
      // e.preventDefault();
      try {
        const apiData = await generateOTPCustomer(mobile);
        if (apiData.error) {
          setError(apiData.error);
        } else {
          console.log("checkMobileNumber", apiData);

          setBackendOTP(apiData.data.otp.otp);
          setId(apiData.data.customerId);
          localStorage.setItem("user_id", apiData.data.customerId);
        }
      } catch (err) {
        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError(err.message);
        }
      }
    } else {
      setError("Mobile number must contain 10 digits");
    }

    setLoader(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "168.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        <h2 className="orderFailedScreenText">Booking Failed</h2>
      </div>
      <div className="phoneNumberDivLogin">
        {true && (
          <>
            <div className="form-group">
              <label htmlFor="" className="loginScreenTitle">
                {" "}
                "We apologize, but it seems there was an issue processing your
                payment. Please verify your payment details and try again. If
                the problem persists, please contact our support team for
                assistance. Thank you for your understanding."
              </label>
              {/* <Button
                sx={{
                  marginTop: "16px",
                  width: "200.39px",
                  height: "38px",
                  background: "#36b54a",
                  border: "1px solid #36b54a",
                  color: "#FFFFFF",
                  // marginBottom:"5px",
                  padding: "7px 16.39px 7px 16px",

                  borderRadius: "9.6px",
                  fontFamily: "Montserrat",
                  fontWeight: "700",

                  // marginBottom:"10px"
                  // marginBottom:"-20px"
                }}
                onClick={() => {
                  navigate("/user");
                }}
              >
                {"View All Bookings "}
              </Button> */}
              <Button
                sx={{
                  marginTop: "16px",
                  marginLeft: "16px",
                  width: "200.39px",
                  height: "38px",
                  background: "#36b54a",
                  border: "1px solid #36b54a",
                  color: "#FFFFFF",
                  // marginBottom:"5px",
                  padding: "7px 16.39px 7px 16px",

                  borderRadius: "9.6px",
                  fontFamily: "Montserrat",
                  fontWeight: "700",

                  // marginBottom:"10px"
                  // marginBottom:"-20px"
                }}
                onClick={() => {
                  navigate("/");
                }}
                className="button_onHover"
              >
                {"Continue"}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
